<i18n>
ru:
  unknown: 'Неизвестный попап {popup}'
ua:
  unknown: 'Невідомий попап {popup}'
us:
  unknown: 'Unknown popup {popup}'
</i18n>

<template>
  <div
    id="v-popup"
    class="v-popup"
    v-on-key-press="toggleKeyPress"
    v-show="popupStore.popupVisible"
    @mousedown="outsideClick"
  >
    <div
      id="v-popup-wrapper"
      class="v-popup-wrapper"
      :class="[
        className,
        captchaVisible ? 'v-popup-wrapper__recaptcha' : '',
        popupStore.shake ? 'v-popup-shake' : '',
        popupPlaceCloseButtonInside ? 'v-popup-close-inside' : ''
      ]"
      :data-test-id="autoTest.dataTestId"
    >
      <div
        v-if="popupStore.popupVisible"
        :key="hash"
        class="v-popup-content"
        :class="{
          'v-popup-content--close-outside': !popupPlaceCloseButtonInside
        }"
      >
        <div
          v-if="popupStore.popupClosable"
          class="v-popup-close-button"
          :class="[
            popupPlaceCloseButtonInside ? 'v-popup-close-button-inside' : 'v-popup-close-button-outside'
          ]"
          data-test-id="popup-close-button"
          @click="async () => await popupStore.closePopup()"
        >
          <icon-general-cross />
        </div>
        <lazy-account-change-password
          v-if="popupStore.popupComponent === 'accountChangePasswordPopup'"
        />
        <lazy-account-change-phone v-else-if="popupStore.popupComponent === 'accountChangePhonePopup'" />
        <lazy-account-delete-account v-else-if="popupStore.popupComponent === 'accountDeletePopup'" />
        <lazy-account-personal-cabinet-parts-edit-profile
          v-else-if="popupStore.popupComponent === 'accountEditProfilePopup'"
        />
        <lazy-account-management v-else-if="popupStore.popupComponent === 'accountManagementPopup'" />
        <lazy-account-personal-cabinet-parts-order-details-popup
          v-else-if="popupStore.popupComponent === 'accountOrderDetailsPopup'"
          :order="popupStore.popupProperties.get('order')"
        />
        <lazy-account-personal-cabinet-parts-order-repeat-popup
          v-else-if="popupStore.popupComponent === 'accountOrderRepeatPopup'"
          :order="popupStore.popupProperties.get('order')"
        />
        <lazy-account-personal-cabinet-parts-points-history
          v-else-if="popupStore.popupComponent === 'accountPointsHistory'"
        />
        <lazy-account-personal-cabinet-parts-ranks-popup
          v-else-if="popupStore.popupComponent === 'accountRanksPopup'"
        />
        <lazy-address-selector-popup
          v-else-if="popupStore.popupComponent === 'addressSelectorPopup'"
          :from-cart="popupStore.popupProperties.get('fromCart')"
          :on-entrance="popupStore.popupProperties.get('onEntrance')"
          :on-product-add="popupStore.popupProperties.get('onProductAdd')"
        />
        <lazy-home-banners-minas-tirith-popup
          v-else-if="popupStore.popupComponent === 'bannerStories'"
          :index="popupStore.popupProperties.get('index')"
        />
        <lazy-cart-birthday-gift-popup
          v-else-if="popupStore.popupComponent === 'cartBirthdayGiftPopup'"
        />
        <lazy-cart-changed-products-popup
          v-else-if="popupStore.popupComponent === 'cartChangedProductsPopup'"
          :content="popupStore.popupProperties.get('content')"
          :ok-function="popupStore.popupProperties.get('okFunction')"
        />
        <lazy-cart-cleanup-popup v-else-if="popupStore.popupComponent === 'cartCleanupPopup'" />
        <lazy-address-city-selector-popup
          v-else-if="popupStore.popupComponent === 'citySelectorPopup'"
        />
        <lazy-popup-common-confirm
          v-else-if="popupStore.popupComponent === 'confirmPopup'"
          :message="popupStore.popupProperties.get('message')"
          :no-function="popupStore.popupProperties.get('noFunction')"
          :no-text="popupStore.popupProperties.get('noText')"
          :title="popupStore.popupProperties.get('title')"
          :type="popupStore.popupProperties.get('type')"
          :yes-function="popupStore.popupProperties.get('yesFunction')"
          :yes-or-no="popupStore.popupProperties.get('yesOrNo')"
          :yes-text="popupStore.popupProperties.get('yesText')"
        />
        <lazy-cart-delivery-time-rath-celerdain-popup
          v-else-if="popupStore.popupComponent === 'deliveryTimeRathCelerdainPopup'"
          :enabled-a-s-a-p="popupStore.popupProperties.get('enabledASAP')"
          :enabled-scheduled="popupStore.popupProperties.get('enabledScheduled')"
          :title="popupStore.popupProperties.get('title')"
        />
        <lazy-cart-delivery-time-rath-dinen-popup
          v-else-if="popupStore.popupComponent === 'deliveryTimeRathDinenPopup'"
          :enabled-a-s-a-p="popupStore.popupProperties.get('enabledASAP')"
          :enabled-scheduled="popupStore.popupProperties.get('enabledScheduled')"
          :title="popupStore.popupProperties.get('title')"
        />
        <lazy-header-parts-easter-egg-popup v-else-if="popupStore.popupComponent === 'easterEggPopup'" />
        <lazy-popup-common-error-popup
          v-else-if="popupStore.popupComponent === 'errorPopup'"
          :data-test-id="popupStore.popupProperties.get('dataTestId')"
          :icon="popupStore.popupProperties.get('icon')"
          :message="popupStore.popupProperties.get('message')"
          :title="popupStore.popupProperties.get('title')"
          :type="popupStore.popupProperties.get('type')"
        />
        <lazy-page-feedback-image-popup
          v-else-if="popupStore.popupComponent === 'feedbackImagePopup'"
          :img-url="popupStore.popupProperties.get('imgUrl')"
        />
        <lazy-page-feedback-popup v-else-if="popupStore.popupComponent === 'feedbackPopup'" />
        <lazy-common-gallery-popup
          v-else-if="popupStore.popupComponent === 'galleryPopup'"
          :active-number="popupStore.popupProperties.get('activeNumber')"
          :auto-play-delay="popupStore.popupProperties.get('autoPlayDelay')"
          :images="popupStore.popupProperties.get('images')"
          :show-thumbs="popupStore.popupProperties.get('showThumbs')"
        />
        <lazy-popup-common-input-popup
          v-else-if="popupStore.popupComponent === 'inputPopup'"
          :data-test-id="popupStore.popupProperties.get('dataTestId')"
          :initial-text="popupStore.popupProperties.get('initialText')"
          :on-confirm="popupStore.popupProperties.get('onConfirm')"
          :title="popupStore.popupProperties.get('title')"
        />
        <lazy-maps-saved-addresses
          v-else-if="popupStore.popupComponent === 'mapsSavedAddressesPopup'"
          :address="popupStore.popupProperties.get('address')"
        />
        <lazy-menu-product-constructor-card-page
          v-else-if="
            popupStore.popupComponent === 'menuConstructorPopup' ||
            popupStore.popupComponent === 'menuConstructorPopupSmall'
          "
          :constructor-id="popupStore.popupProperties.get('constructorId')"
        />
        <lazy-menu-product-slots-half-page
          v-else-if="popupStore.popupComponent === 'menuSlotsHalfPopup'"
          :slot-id="popupStore.popupProperties.get('slotId')"
        />
        <lazy-menu-product-slots-lunch-page
          v-else-if="popupStore.popupComponent === 'menuSlotsLunchPopup'"
          :slot-id="popupStore.popupProperties.get('slotId')"
        />
        <lazy-menu-product-slots-lunch-mobile-popup
          v-else-if="popupStore.popupComponent === 'menuSlotsLunchMobilePopup'"
          :select-slot="popupStore.popupProperties.get('selectSlot')"
          :selected-slot-id="popupStore.popupProperties.get('selectedSlotId')"
          :slot-product="popupStore.popupProperties.get('slot')"
        />
        <lazy-menu-product-slots-quarter-page
          v-else-if="popupStore.popupComponent === 'menuSlotsQuarterPopup'"
          :slot-id="popupStore.popupProperties.get('slotId')"
        />
        <lazy-menu-product-base-page
          v-else-if="popupStore.popupComponent === 'menuProductPopup'"
          :product-id="popupStore.popupProperties.get('productId')"
        />
        <lazy-menu-gifts-popup
          v-else-if="popupStore.popupComponent === 'menuGiftsPopup'"
          :allow-multiple="popupStore.popupProperties.get('allowMultiple')"
          :gifts="popupStore.popupProperties.get('gifts')"
          :type="popupStore.popupProperties.get('type')"
        />
        <lazy-popup-common-message-popup
          v-else-if="popupStore.popupComponent === 'messagePopup'"
          :data-test-id="popupStore.popupProperties.get('dataTestId')"
          :icon="popupStore.popupProperties.get('icon')"
          :message="popupStore.popupProperties.get('message')"
          :title="popupStore.popupProperties.get('title')"
          :type="popupStore.popupProperties.get('type')"
        />
        <lazy-account-personal-cabinet-parts-order-repeat-redirect-popup
          v-else-if="popupStore.popupComponent === 'orderRepeatRedirectPopup'"
          :order="popupStore.popupProperties.get('order')"
          :title-key="popupStore.popupProperties.get('titleKey')"
        />
        <lazy-clientside-promo-in-url-popup
          v-else-if="popupStore.popupComponent === 'promoInUrlPopup'"
          :code="popupStore.popupProperties.get('code')"
          :subtitle="popupStore.popupProperties.get('subtitle')"
          :title="popupStore.popupProperties.get('title')"
        />
        <lazy-header-parts-qr-app-popup v-else-if="popupStore.popupComponent === 'qrAppPopup'" />
        <lazy-cart-qr-pay-popup
          v-else-if="popupStore.popupComponent === 'qrPayPopup'"
          :order-id="popupStore.popupProperties.get('orderId')"
          :total-sum="popupStore.popupProperties.get('totalSum')"
        />
        <lazy-account-saved-addresses-popup
          v-else-if="popupStore.popupComponent === 'savedAddressesPopup'"
          :address="popupStore.popupProperties.get('address')"
        />
        <lazy-page-table-reservation-form
          v-else-if="popupStore.popupComponent === 'tableReservationPopup'"
        />
        <lazy-page-vacancies-popup
          v-else-if="popupStore.popupComponent === 'vacancyPopup'"
          :id="popupStore.popupProperties.get('id')"
        />
        <div
          v-else
          class="v-error-color"
          v-html="translate('mainPopup.unknown', { popup: popupStore.popupComponent ?? '' })"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PopupComponent } from '~types/popupStore'

import { type AutoTest, useCommon, useWindowSize, vOnKeyPress } from '@arora/common'

function makeClass(str: string): string {
  return `v-popup-${camelCaseConvert(str)}`
}

const popupStore = usePopupStore()

const appConfig = useAppConfig()
const { eventOn } = useEmitter()
const { camelCaseConvert } = useCommon()
const { translate } = useI18nSanitized()
const { isSmall } = useWindowSize()

const captchaVisible = ref<boolean>(false)

eventOn('v-captcha-visible', () => {
  captchaVisible.value = true
})
eventOn('v-captcha-hidden', () => {
  captchaVisible.value = false
})

const className = ref<string>('')

const autoTest = ref<AutoTest>({})

async function outsideClick(event: MouseEvent): Promise<void> {
  const element = event.target as HTMLElement

  if (
    element.className !== 'v-popup' ||
    (element.className === 'v-popup' && element.clientWidth <= event.clientX)
  )
    return

  popupStore.closePopupWithShake()
}

const popupPlaceCloseButtonInside = computed<boolean>(() => {
  if (popupStore.popupComponent === 'bannerStories' && isSmall.value) return true
  return appConfig.VueSettingsPreRun.PopupPlaceCloseButtonInside
})

const hash = ref<string>('')

watch(
  () => popupStore.popupComponent,
  async (newContent: PopupComponent | null | undefined) => {
    if (newContent) {
      hash.value = window.btoa(JSON.stringify(popupStore.popupProperties))
      if (popupStore.dataTestId) {
        autoTest.value = {
          dataTestId: popupStore.dataTestId
        }
      } else {
        autoTest.value = {
          dataTestId: camelCaseConvert(newContent)
        }
      }
      className.value = makeClass(newContent)
    }
  }
)

function toggleKeyPress(event: KeyboardEvent): void {
  if (event.code === 'Escape') {
    popupStore.closePopupWithShake()
  }
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-popup-mobile-wrapper {
  padding: 15px;
  max-height: 90vh;

  .v-popup-mobile-lower {
    height: 15px;
  }
}

.v-popup-mobile-recaptcha-content {
  min-height: 60vh;
}

.v-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1061;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: variables.$PopupOverlayBackground;
  backdrop-filter: blur(10px);

  opacity: 0; //for animation

  .v-popup-wrapper {
    position: relative;
    width: auto;
    margin: 1.75rem auto;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - 3.5rem);

    transform: scale(80%, 80%); //for animation

    @include mixins.popup-size;

    &.v-popup-close-inside {
      .v-popup-head {
        @include mixins.sm {
          padding-right: 35px;
        }
      }
    }

    @include mixins.sm {
      margin: 5px auto 0;
      min-height: calc(100% - 1.75rem);
    }
  }

  .v-popup-wrapper__recaptcha {
    min-height: 415px;
  }

  &-content {
    @include mixins.trans;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    outline: 0;
    background: variables.$PopupBackground;
    color: variables.$PopupColor;
    pointer-events: auto;

    border-radius: variables.$BorderRadius;
    padding: variables.$PopupPadding;
    transform: translate(0, -50px);
    margin: 0.5rem;

    @include mixins.sm {
      padding: 18px 22px;
      transform: none;
      margin: 5px 16px 0;
    }

    &--close-outside {
      @include mixins.sm {
        margin: 50px 16px 0; //50 is for close button
      }
    }
  }
  .v-popup-account-management-popup {
    .v-popup-content {
      @include mixins.sm {
        padding: variables.$PopupPadding;
      }
    }
  }

  .v-popup-gallery-popup {
    @include mixins.popup-size--large;

    .v-popup-content {
      border-radius: variables.$BorderRadius;
      padding: 0;
      background: transparent;
      display: flex;
      align-items: center;
    }
  }

  .v-popup-banner-stories {
    @include mixins.popup-size;

    width: auto;

    display: flex;
    align-items: center;

    .v-popup-content {
      width: auto;
      border-radius: variables.$BorderRadius;
      padding: 0;
      background: transparent;
    }

    .v-popup-close-button-inside {
      right: 35px;
      top: 27px;

      @include mixins.sm {
        right: 8px;
        top: 24px;
      }

      svg {
        width: 24px;
        height: 24px;

        path {
          stroke: variables.$BodyElementsBackground;
        }
      }
    }
  }

  .v-popup-account-change-phone-popup,
  .v-popup-account-edit-profile-popup,
  .v-popup-account-points-history,
  .v-popup-cart-cleanup-popup,
  .v-popup-easter-egg-popup,
  .v-popup-input-popup,
  .v-popup-message-popup,
  .v-popup-ok-popup-form,
  .v-popup-order-repeat-redirect-popup,
  .v-popup-promo-in-url-popup,
  .v-popup-qr-app-popup,
  .v-popup-account-change-password-popup {
    @include mixins.popup-size--small;
  }

  .v-popup-account-order-details-popup,
  .v-popup-account-order-repeat-popup,
  .v-popup-account-ranks-popup,
  .v-popup-address-selector-popup,
  .v-popup-cart-birthday-gift-popup,
  .v-popup-cart-changed-products-popup,
  .v-popup-city-selector-popup,
  .v-popup-confirm-popup,
  .v-popup-feedback-image-popup,
  .v-popup-feedback-popup,
  .v-popup-maps-saved-addresses-popup,
  .v-popup-menu-constructor-popup-small,
  .v-popup-menu-slots-lunch-mobile-popup,
  .v-popup-partners-popup,
  .v-popup-saved-address-popup,
  .v-popup-table-reservation-popup,
  .v-popup-vacancy-popup {
    @include mixins.popup-size;
  }

  .v-popup-account-delete-popup,
  .v-popup-delivery-time-rath-celerdain-popup,
  .v-popup-delivery-time-rath-dinen-popup,
  .v-popup-account-management-popup {
    @include mixins.popup-size--medium;
  }

  .v-popup-qr-pay-popup {
    @include mixins.popup-size;
    padding-bottom: 1.1rem;
  }
  .v-popup-menu-constructor-popup {
    @include mixins.popup-size--extra-large;
    padding: 15px;
  }
  .v-popup-offer-choice-form {
    @include mixins.popup-size--large;
  }
  .v-popup-menu-gifts-popup,
  .v-popup-menu-slots-half-popup,
  .v-popup-menu-slots-lunch-popup,
  .v-popup-menu-slots-quarter-popup {
    @include mixins.popup-size--extra-large;
  }

  .v-popup-menu-product-popup {
    &__KhazadDum {
      @include mixins.popup-size--large;
    }
    &__Moria {
      @include mixins.popup-size;
    }
    &__Mazarbul {
      @include mixins.popup-size--large;

      .v-popup-content {
        padding: 0;
      }
    }
  }
}

.v-popup-close-button {
  cursor: pointer;
  z-index: 1061;

  color: variables.$PopupOverlayColor;

  &-outside {
    position: absolute;
    top: -5px;
    right: -40px;

    &:hover {
      svg {
        path {
          stroke: variables.$BodyElementsBackground;
        }
      }
    }

    @include mixins.sm {
      top: -50px;
      right: 20px;
    }

    svg {
      width: 40px;
      height: 40px;

      path {
        @include mixins.trans;
        stroke: variables.$BodyBackgroundDarker;
      }
    }
  }

  &-inside {
    font-size: 24px;
    position: absolute;
    right: 20px;
    top: 15px;

    &:hover {
      svg {
        path {
          stroke: variables.$BodyTextColor;
        }
      }
    }

    svg {
      width: 24px;
      height: 24px;

      path {
        @include mixins.trans;
        stroke: variables.$BodyTextColorLight;
      }
    }
  }
}

.v-open-overflow {
  overflow: hidden;
  .v-popup-content {
    transform: none;
  }
}

.v-popup-head {
  display: flex;
  justify-content: space-between;
  font-size: variables.$TextSizeH3;
  margin-bottom: 10px;

  .v-warning-color {
    i {
      margin-right: 10px;
    }
  }

  .v-success-color {
    i {
      margin-right: 10px;
    }
  }

  .v-error-color {
    i {
      margin-right: 10px;
    }
  }
}

.v-popup-buttons {
  margin-top: 32px;
}

.v-popup-shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
