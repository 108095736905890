import { default as aboute0GHEWzDPHMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/about.vue?macro=true";
import { default as cabinetDiqEpd2384Meta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/account/cabinet.vue?macro=true";
import { default as _91id_93rP1jDXliWwMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/actions/[id].vue?macro=true";
import { default as list34s6ufyMmcMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/actions/list.vue?macro=true";
import { default as _91id_933sr7KR5kBHMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/articles/[id].vue?macro=true";
import { default as listG2FgR1s4oUMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/articles/list.vue?macro=true";
import { default as finalStepgzTdKGM9JTMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/cart/finalStep.vue?macro=true";
import { default as firstStepj5XnmDsn71Meta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/cart/firstStep.vue?macro=true";
import { default as secondStepiqkNzdAsN7Meta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/cart/secondStep.vue?macro=true";
import { default as contacts9GtHuEr4MuMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/contacts.vue?macro=true";
import { default as customogLr7VlutpMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/custom.vue?macro=true";
import { default as deliverynOZqVFc54QMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/delivery.vue?macro=true";
import { default as feedbackwRIxoZ26zBMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/feedback.vue?macro=true";
import { default as indexJHooolxO39Meta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/index.vue?macro=true";
import { default as legalaZ4YXfPKiRMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/legal.vue?macro=true";
import { default as _91id_93H7vC4O1T0hMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/group/[id].vue?macro=true";
import { default as _91id_93nfoJpqVzf1Meta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue?macro=true";
import { default as partnership84sWN04ACRMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/partnership.vue?macro=true";
import { default as _91id_93vHwEyABSpKMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/restaurants/[id].vue?macro=true";
import { default as list822O0h49rLMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/restaurants/list.vue?macro=true";
import { default as offzw1grXUj7kMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/system/off.vue?macro=true";
import { default as vacancycYUmlWPAGBMeta } from "D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/vacancy.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/about.vue")
  },
  {
    name: "account-cabinet",
    path: "/cabinet",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/account/cabinet.vue")
  },
  {
    name: "actions-id",
    path: "/action/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/actions/[id].vue")
  },
  {
    name: "actions-list",
    path: "/actions",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/actions/list.vue")
  },
  {
    name: "articles-id",
    path: "/articles/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/articles/[id].vue")
  },
  {
    name: "articles-list",
    path: "/articles",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/articles/list.vue")
  },
  {
    name: "cart-finalStep",
    path: "/order/complete/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/cart/finalStep.vue")
  },
  {
    name: "cart-firstStep",
    path: "/order",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/cart/firstStep.vue")
  },
  {
    name: "cart-secondStep",
    path: "/order/delivery",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/cart/secondStep.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/contacts.vue")
  },
  {
    name: "custom",
    path: "/custom",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/custom.vue")
  },
  {
    name: "delivery",
    path: "/delivery",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/delivery.vue")
  },
  {
    name: "feedback",
    path: "/feedback/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/feedback.vue")
  },
  {
    name: "index",
    path: "/",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/legal.vue")
  },
  {
    name: "menu-group-id",
    path: "/menu/group/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/group/[id].vue")
  },
  {
    name: "menu-product-id",
    path: "/menu/product/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "partnership",
    path: "/partners",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/partnership.vue")
  },
  {
    name: "restaurants-id",
    path: "/restaurants-details/:id()",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/restaurants/[id].vue")
  },
  {
    name: "restaurants-list",
    path: "/rest",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/restaurants/list.vue")
  },
  {
    name: "system-off",
    path: "/system/off",
    meta: {"HideHeader":true,"HideFooter":true},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/system/off.vue")
  },
  {
    name: "vacancy",
    path: "/vacancy",
    meta: {"MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/vacancy.vue")
  },
  {
    path: "/feedback",
    name: "feedback-default",
    redirect: "/feedback/1"
  },
  {
    name: "group|39537111-1e18-4bac-9454-b02a009d3d37",
    path: "/catalog/picca/all",
    meta: {"ID":"39537111-1e18-4bac-9454-b02a009d3d37","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"39537111-1e18-4bac-9454-b02a009d3d37","Title":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/group/[id].vue")
  },
  {
    name: "group|88cd3140-5d31-4223-965f-adea00647536",
    path: "/catalog/klassicheskaya-pizza",
    meta: {"ID":"88cd3140-5d31-4223-965f-adea00647536","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","Title":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/group/[id].vue")
  },
  {
    name: "group|19db749b-f0cc-4fa5-8162-b08100cb3c6c",
    path: "/catalog/rimskaya-pizza",
    meta: {"ID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","Title":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/group/[id].vue")
  },
  {
    name: "group|7fdabbb4-f2fe-4639-9608-ae2800938ea0",
    path: "/catalog/burgery",
    meta: {"ID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","GroupID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","SubgroupID":null,"Title":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/group/[id].vue")
  },
  {
    path: "/catalog/burgery/all",
    name: "group|7fdabbb4-f2fe-4639-9608-ae2800938ea0|redirect-all",
    redirect: "/catalog/burgery"
  },
  {
    name: "group|d51fb741-dc07-4264-84ea-b0e000cd8fa2",
    path: "/catalog/furshet/all",
    meta: {"ID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","Title":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/group/[id].vue")
  },
  {
    name: "group|77c22e69-2462-4bd5-9240-b0e000cca68b",
    path: "/catalog/salat",
    meta: {"ID":"77c22e69-2462-4bd5-9240-b0e000cca68b","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"77c22e69-2462-4bd5-9240-b0e000cca68b","Title":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/group/[id].vue")
  },
  {
    name: "group|1ccdeee5-60bf-4184-ba52-b0e000ccf59b",
    path: "/catalog/bruskety",
    meta: {"ID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","Title":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/group/[id].vue")
  },
  {
    name: "group|065cfcd4-7eab-4818-ad12-b0e000cccc3c",
    path: "/catalog/kanape",
    meta: {"ID":"065cfcd4-7eab-4818-ad12-b0e000cccc3c","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"065cfcd4-7eab-4818-ad12-b0e000cccc3c","Title":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/group/[id].vue")
  },
  {
    name: "group|dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea",
    path: "/catalog/tartaletki",
    meta: {"ID":"dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea","Title":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/group/[id].vue")
  },
  {
    name: "group|2ba54d74-f42e-4df4-8d69-b0e600cba595",
    path: "/catalog/goryachie-zakuski",
    meta: {"ID":"2ba54d74-f42e-4df4-8d69-b0e600cba595","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"2ba54d74-f42e-4df4-8d69-b0e600cba595","Title":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/group/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|00b30a99-9e27-4aec-85af-0adb779ff224",
    path: "/product/00b30a99-9e27-4aec-85af-0adb779ff224",
    meta: {"ID":"00b30a99-9e27-4aec-85af-0adb779ff224","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|327b4329-2465-4bfc-b424-af660086214b|012c02cd-a8c9-4ae1-b00b-33441d5f915d",
    path: "/product/012c02cd-a8c9-4ae1-b00b-33441d5f915d",
    meta: {"ID":"012c02cd-a8c9-4ae1-b00b-33441d5f915d","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bc37d11b-8dce-49ce-a28f-b12a00858730|0138c830-54a8-4355-899e-2703c2df1007",
    path: "/product/0138c830-54a8-4355-899e-2703c2df1007",
    meta: {"ID":"0138c830-54a8-4355-899e-2703c2df1007","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|8899d7d0-89e7-4532-99ef-b02800d16bfd|016f2ba6-a6fc-4ad8-8fd6-921fa5641456",
    path: "/product/016f2ba6-a6fc-4ad8-8fd6-921fa5641456",
    meta: {"ID":"016f2ba6-a6fc-4ad8-8fd6-921fa5641456","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|1ccdeee5-60bf-4184-ba52-b0e000ccf59b|01d1bc36-36d9-46d7-8b90-e1bbab6fbee7",
    path: "/product/kanape-kartoshechka-s-salom-i-ovoschami",
    meta: {"ID":"01d1bc36-36d9-46d7-8b90-e1bbab6fbee7","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupTitle":"Брускетты","GroupLink":"/catalog/bruskety","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|028d9752-7dfd-461a-ab08-f1c056734587",
    path: "/product/028d9752-7dfd-461a-ab08-f1c056734587",
    meta: {"ID":"028d9752-7dfd-461a-ab08-f1c056734587","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|02aed1a8-f64b-4a9f-a85c-adc44fccef76",
    path: "/product/02aed1a8-f64b-4a9f-a85c-adc44fccef76",
    meta: {"ID":"02aed1a8-f64b-4a9f-a85c-adc44fccef76","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7a725ed6-7233-47a5-a705-aeb400a2433d|03fec3dd-82d2-42c9-85b0-b185ea6c8097",
    path: "/product/03fec3dd-82d2-42c9-85b0-b185ea6c8097",
    meta: {"ID":"03fec3dd-82d2-42c9-85b0-b185ea6c8097","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|766563a0-82d3-47ce-8d74-aeda00eaca0e|04d8e847-4f4b-4f2b-8ed1-e03de79ec6a4",
    path: "/product/04d8e847-4f4b-4f2b-8ed1-e03de79ec6a4",
    meta: {"ID":"04d8e847-4f4b-4f2b-8ed1-e03de79ec6a4","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|058d85c4-3571-4efb-b768-ae65cdffe24b",
    path: "/product/058d85c4-3571-4efb-b768-ae65cdffe24b",
    meta: {"ID":"058d85c4-3571-4efb-b768-ae65cdffe24b","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|06db3d68-a67f-4b6a-8553-bb5b014d7328",
    path: "/product/06db3d68-a67f-4b6a-8553-bb5b014d7328",
    meta: {"ID":"06db3d68-a67f-4b6a-8553-bb5b014d7328","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|1ccdeee5-60bf-4184-ba52-b0e000ccf59b|078bb6e7-b9f4-41b3-aeef-93ff41c8e17b",
    path: "/product/kanape-s-buzheninoy-i-cherri",
    meta: {"ID":"078bb6e7-b9f4-41b3-aeef-93ff41c8e17b","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupTitle":"Брускетты","GroupLink":"/catalog/bruskety","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|77c22e69-2462-4bd5-9240-b0e000cca68b|07cda2fe-92e2-4c5b-8ad3-cc0d20635ff6",
    path: "/product/salat-olive-s-kolbasoy-1-kg",
    meta: {"ID":"07cda2fe-92e2-4c5b-8ad3-cc0d20635ff6","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"77c22e69-2462-4bd5-9240-b0e000cca68b","GroupTitle":"Салат","GroupLink":"/catalog/salat","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|e0bf81c8-afe8-4300-a2d7-b02800d1792b|0841385b-e0ff-4a02-8a45-a47f387f311f",
    path: "/product/0841385b-e0ff-4a02-8a45-a47f387f311f",
    meta: {"ID":"0841385b-e0ff-4a02-8a45-a47f387f311f","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|0842a71e-7b27-4528-8eeb-55ef68b03b8f",
    path: "/product/0842a71e-7b27-4528-8eeb-55ef68b03b8f",
    meta: {"ID":"0842a71e-7b27-4528-8eeb-55ef68b03b8f","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|065cfcd4-7eab-4818-ad12-b0e000cccc3c|099a163b-041c-4521-8d06-18c0083c1b9e",
    path: "/product/kanape-ovoschnoe",
    meta: {"ID":"099a163b-041c-4521-8d06-18c0083c1b9e","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"065cfcd4-7eab-4818-ad12-b0e000cccc3c","GroupTitle":"Канапе","GroupLink":"/catalog/kanape","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|8899d7d0-89e7-4532-99ef-b02800d16bfd|09ebfabb-dc53-4a80-b486-04a29e97a22e",
    path: "/product/09ebfabb-dc53-4a80-b486-04a29e97a22e",
    meta: {"ID":"09ebfabb-dc53-4a80-b486-04a29e97a22e","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|1ccdeee5-60bf-4184-ba52-b0e000ccf59b|0a182573-6cc0-49fb-a26b-6bd55eb81d6d",
    path: "/product/khrustyaschaya-chiabatta-s-lososem-i-slivochnym-syrom",
    meta: {"ID":"0a182573-6cc0-49fb-a26b-6bd55eb81d6d","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupTitle":"Брускетты","GroupLink":"/catalog/bruskety","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|0b61cea4-6f6d-4002-a7e9-ddf6a193c300",
    path: "/product/0b61cea4-6f6d-4002-a7e9-ddf6a193c300",
    meta: {"ID":"0b61cea4-6f6d-4002-a7e9-ddf6a193c300","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|998d3729-dd15-45c9-aa7d-b0c10086fc3e|0c2b2dd2-3a9f-4de6-a12a-966eeddf0145",
    path: "/product/0c2b2dd2-3a9f-4de6-a12a-966eeddf0145",
    meta: {"ID":"0c2b2dd2-3a9f-4de6-a12a-966eeddf0145","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|0c7c33ef-49c7-4034-a95d-c67b128387d3",
    path: "/product/Tom-yam-s-kuritsey-v",
    meta: {"ID":"0c7c33ef-49c7-4034-a95d-c67b128387d3","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|0d4f08fa-1906-4293-9ec1-3704683eb1d8",
    path: "/product/zapechenniy-atlanticheskiy",
    meta: {"ID":"0d4f08fa-1906-4293-9ec1-3704683eb1d8","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|0e6e1ea6-b524-4b98-9e7b-e5c9567accb2",
    path: "/product/0e6e1ea6-b524-4b98-9e7b-e5c9567accb2",
    meta: {"ID":"0e6e1ea6-b524-4b98-9e7b-e5c9567accb2","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|0e72b37c-01dd-48db-b1a1-d65ddaf66a26",
    path: "/product/0e72b37c-01dd-48db-b1a1-d65ddaf66a26",
    meta: {"ID":"0e72b37c-01dd-48db-b1a1-d65ddaf66a26","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|0e987c73-f018-4c07-ad8d-f8f173327e87",
    path: "/product/0e987c73-f018-4c07-ad8d-f8f173327e87",
    meta: {"ID":"0e987c73-f018-4c07-ad8d-f8f173327e87","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|1ccdeee5-60bf-4184-ba52-b0e000ccf59b|0f6c47bd-d460-4e63-a47e-641152b6f247",
    path: "/product/kanape-losos-s-olivkoy-i-slivochnym-syrom",
    meta: {"ID":"0f6c47bd-d460-4e63-a47e-641152b6f247","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupTitle":"Брускетты","GroupLink":"/catalog/bruskety","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|766563a0-82d3-47ce-8d74-aeda00eaca0e|0f8c5ee1-14bf-4ed3-9ac1-b83e2759e507",
    path: "/product/0f8c5ee1-14bf-4ed3-9ac1-b83e2759e507",
    meta: {"ID":"0f8c5ee1-14bf-4ed3-9ac1-b83e2759e507","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bc37d11b-8dce-49ce-a28f-b12a00858730|0fbf764a-8298-48c4-923f-ef730396b6b7",
    path: "/product/0fbf764a-8298-48c4-923f-ef730396b6b7",
    meta: {"ID":"0fbf764a-8298-48c4-923f-ef730396b6b7","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|102b1b55-8c8a-4766-b09d-99ec2b708aac",
    path: "/product/102b1b55-8c8a-4766-b09d-99ec2b708aac",
    meta: {"ID":"102b1b55-8c8a-4766-b09d-99ec2b708aac","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|10300372-4563-4fd0-a592-57a51dc6cf16",
    path: "/product/10300372-4563-4fd0-a592-57a51dc6cf16",
    meta: {"ID":"10300372-4563-4fd0-a592-57a51dc6cf16","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|10637ba5-1dc8-47ea-aa16-f5432abae6ad",
    path: "/product/goryachiy-losos",
    meta: {"ID":"10637ba5-1dc8-47ea-aa16-f5432abae6ad","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|11336c9e-75e6-4e97-bdd0-7078124c836b",
    path: "/product/11336c9e-75e6-4e97-bdd0-7078124c836b",
    meta: {"ID":"11336c9e-75e6-4e97-bdd0-7078124c836b","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|12ac16ef-52b2-4572-8819-0a1d9709a570",
    path: "/product/12ac16ef-52b2-4572-8819-0a1d9709a570",
    meta: {"ID":"12ac16ef-52b2-4572-8819-0a1d9709a570","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|12e691ba-66a8-4c93-8e27-0525a2e04e1d",
    path: "/product/12e691ba-66a8-4c93-8e27-0525a2e04e1d",
    meta: {"ID":"12e691ba-66a8-4c93-8e27-0525a2e04e1d","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|77c22e69-2462-4bd5-9240-b0e000cca68b|133fea9b-dac5-46c2-a3b6-d6cbe5895309",
    path: "/product/salat-kraboviy-1-kg",
    meta: {"ID":"133fea9b-dac5-46c2-a3b6-d6cbe5895309","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"77c22e69-2462-4bd5-9240-b0e000cca68b","GroupTitle":"Салат","GroupLink":"/catalog/salat","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|6ad09738-0b2a-4cb3-84d3-b0f2008e75d8|13483e42-998e-4a3b-a2b9-deaf26d30bdf",
    path: "/product/13483e42-998e-4a3b-a2b9-deaf26d30bdf",
    meta: {"ID":"13483e42-998e-4a3b-a2b9-deaf26d30bdf","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|13811f66-37d2-4efc-a2b6-d93fc409aeae",
    path: "/product/13811f66-37d2-4efc-a2b6-d93fc409aeae",
    meta: {"ID":"13811f66-37d2-4efc-a2b6-d93fc409aeae","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|13a5bc46-7422-4a3f-a7a0-234ac394b22a",
    path: "/product/13a5bc46-7422-4a3f-a7a0-234ac394b22a",
    meta: {"ID":"13a5bc46-7422-4a3f-a7a0-234ac394b22a","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|e3de3a35-eaaf-4471-8e1f-b0e000c7f0b0|140c62f2-0c0e-4d52-acc3-135824bc1c56",
    path: "/product/140c62f2-0c0e-4d52-acc3-135824bc1c56",
    meta: {"ID":"140c62f2-0c0e-4d52-acc3-135824bc1c56","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|142a5304-39cd-47a0-aad5-78d2ccb8696e",
    path: "/product/firmenniy-kilogramm-1",
    meta: {"ID":"142a5304-39cd-47a0-aad5-78d2ccb8696e","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|327b4329-2465-4bfc-b424-af660086214b|153f3c94-e339-45e1-8c86-0ad7f5a5b98f",
    path: "/product/153f3c94-e339-45e1-8c86-0ad7f5a5b98f",
    meta: {"ID":"153f3c94-e339-45e1-8c86-0ad7f5a5b98f","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bc37d11b-8dce-49ce-a28f-b12a00858730|1559ff3b-8634-489b-ab0c-c90b11fb3ae1",
    path: "/product/1559ff3b-8634-489b-ab0c-c90b11fb3ae1",
    meta: {"ID":"1559ff3b-8634-489b-ab0c-c90b11fb3ae1","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|1619ea6d-942d-456a-862e-4f6f2abe6008",
    path: "/product/set-delyuks",
    meta: {"ID":"1619ea6d-942d-456a-862e-4f6f2abe6008","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|1708bc06-5951-4bcf-a3f6-6589e45c2d00",
    path: "/product/1708bc06-5951-4bcf-a3f6-6589e45c2d00",
    meta: {"ID":"1708bc06-5951-4bcf-a3f6-6589e45c2d00","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|5c023689-385b-4718-8278-adfa00fa7f90|17abb9eb-7893-4cef-919c-02329ccad510",
    path: "/product/17abb9eb-7893-4cef-919c-02329ccad510",
    meta: {"ID":"17abb9eb-7893-4cef-919c-02329ccad510","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|17f815e7-beb3-448a-99ef-198e2c5a14cb",
    path: "/product/Borsch-v",
    meta: {"ID":"17f815e7-beb3-448a-99ef-198e2c5a14cb","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|4d1fb455-9ec9-4c99-91e2-b0fb00e2827b|18093372-9171-4ec4-9965-2373b790b774",
    path: "/product/18093372-9171-4ec4-9965-2373b790b774",
    meta: {"ID":"18093372-9171-4ec4-9965-2373b790b774","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|8899d7d0-89e7-4532-99ef-b02800d16bfd|18ed946b-6e41-4095-a40c-0dad79f62431",
    path: "/product/18ed946b-6e41-4095-a40c-0dad79f62431",
    meta: {"ID":"18ed946b-6e41-4095-a40c-0dad79f62431","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|766563a0-82d3-47ce-8d74-aeda00eaca0e|1950651c-07d6-4c3c-910b-bbb93378d216",
    path: "/product/1950651c-07d6-4c3c-910b-bbb93378d216",
    meta: {"ID":"1950651c-07d6-4c3c-910b-bbb93378d216","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|6e7b5518-1053-459c-b5a3-af4100e646fd|197bb4fa-b94b-42c3-81e5-642ee8c4dd86",
    path: "/product/197bb4fa-b94b-42c3-81e5-642ee8c4dd86",
    meta: {"ID":"197bb4fa-b94b-42c3-81e5-642ee8c4dd86","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|19d7a0b6-b5bf-4fe6-b3c8-019d92dc2a10",
    path: "/product/kilogramm",
    meta: {"ID":"19d7a0b6-b5bf-4fe6-b3c8-019d92dc2a10","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|1a0e5dbb-0b4e-42e6-8752-01450e686a0b",
    path: "/product/1a0e5dbb-0b4e-42e6-8752-01450e686a0b",
    meta: {"ID":"1a0e5dbb-0b4e-42e6-8752-01450e686a0b","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|1a4a8312-044c-4b40-865d-8a6566baabfe",
    path: "/product/1a4a8312-044c-4b40-865d-8a6566baabfe",
    meta: {"ID":"1a4a8312-044c-4b40-865d-8a6566baabfe","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|1a563137-38f0-4528-a4ca-ecc0e115b276",
    path: "/product/1a563137-38f0-4528-a4ca-ecc0e115b276",
    meta: {"ID":"1a563137-38f0-4528-a4ca-ecc0e115b276","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|1b3a61e6-de17-4393-b6f1-200881acb8ae",
    path: "/product/1b3a61e6-de17-4393-b6f1-200881acb8ae",
    meta: {"ID":"1b3a61e6-de17-4393-b6f1-200881acb8ae","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|1b3ba20e-845e-47ca-b6ba-aecb6c104bb3",
    path: "/product/zapecheniy-killogram",
    meta: {"ID":"1b3ba20e-845e-47ca-b6ba-aecb6c104bb3","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|1c194d4a-b6e1-4632-8e48-8f37145d1c09",
    path: "/product/1c194d4a-b6e1-4632-8e48-8f37145d1c09",
    meta: {"ID":"1c194d4a-b6e1-4632-8e48-8f37145d1c09","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b1a92c89-deb6-45a8-95d4-b12300a986e5|1c2a00db-01ef-4455-a8af-4f2cae6a494d",
    path: "/product/1c2a00db-01ef-4455-a8af-4f2cae6a494d",
    meta: {"ID":"1c2a00db-01ef-4455-a8af-4f2cae6a494d","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|1cc1300f-1497-4cce-b05e-1f68f78e5692",
    path: "/product/set-khit-zapecheniy",
    meta: {"ID":"1cc1300f-1497-4cce-b05e-1f68f78e5692","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|998d3729-dd15-45c9-aa7d-b0c10086fc3e|1d3d360a-ff36-41f4-ab3b-c9570ce22f74",
    path: "/product/1d3d360a-ff36-41f4-ab3b-c9570ce22f74",
    meta: {"ID":"1d3d360a-ff36-41f4-ab3b-c9570ce22f74","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|77c22e69-2462-4bd5-9240-b0e000cca68b|1e0c983c-39ef-41f9-bff9-2d90443433ae",
    path: "/product/salat-grecheskiy-1-kg",
    meta: {"ID":"1e0c983c-39ef-41f9-bff9-2d90443433ae","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"77c22e69-2462-4bd5-9240-b0e000cca68b","GroupTitle":"Салат","GroupLink":"/catalog/salat","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|1e13a0f1-7805-4def-9e72-f2c890f3bfeb",
    path: "/product/pizza-rimskaya-klassika",
    meta: {"ID":"1e13a0f1-7805-4def-9e72-f2c890f3bfeb","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|1e2a661a-4a13-403e-9b7b-3b1279b7d93f",
    path: "/product/1e2a661a-4a13-403e-9b7b-3b1279b7d93f",
    meta: {"ID":"1e2a661a-4a13-403e-9b7b-3b1279b7d93f","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|1ec352bd-9f92-4479-add8-927afa25e2b5",
    path: "/product/1ec352bd-9f92-4479-add8-927afa25e2b5",
    meta: {"ID":"1ec352bd-9f92-4479-add8-927afa25e2b5","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|1f5207bd-d934-4ae7-a753-ece3af91997b",
    path: "/product/1f5207bd-d934-4ae7-a753-ece3af91997b",
    meta: {"ID":"1f5207bd-d934-4ae7-a753-ece3af91997b","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|5c023689-385b-4718-8278-adfa00fa7f90|1f5ea90f-0ef5-4bee-bfbd-8ed20b6e9e23",
    path: "/product/1f5ea90f-0ef5-4bee-bfbd-8ed20b6e9e23",
    meta: {"ID":"1f5ea90f-0ef5-4bee-bfbd-8ed20b6e9e23","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|1f62d935-24ac-467c-9cdc-f93b18d02ea8",
    path: "/product/1f62d935-24ac-467c-9cdc-f93b18d02ea8",
    meta: {"ID":"1f62d935-24ac-467c-9cdc-f93b18d02ea8","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|8899d7d0-89e7-4532-99ef-b02800d16bfd|20100743-7a8a-45e1-9a68-09724cf52aa0",
    path: "/product/20100743-7a8a-45e1-9a68-09724cf52aa0",
    meta: {"ID":"20100743-7a8a-45e1-9a68-09724cf52aa0","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|4d1fb455-9ec9-4c99-91e2-b0fb00e2827b|20580914-2c40-450c-8136-60a0b671a5a2",
    path: "/product/20580914-2c40-450c-8136-60a0b671a5a2",
    meta: {"ID":"20580914-2c40-450c-8136-60a0b671a5a2","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|2062f811-a53b-47b6-b241-65df0bd2ad44",
    path: "/product/2062f811-a53b-47b6-b241-65df0bd2ad44",
    meta: {"ID":"2062f811-a53b-47b6-b241-65df0bd2ad44","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|206f3f47-c23a-489d-8004-fb3f8cc33998",
    path: "/product/206f3f47-c23a-489d-8004-fb3f8cc33998",
    meta: {"ID":"206f3f47-c23a-489d-8004-fb3f8cc33998","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|1ccdeee5-60bf-4184-ba52-b0e000ccf59b|2108d828-3d97-4e44-a72f-00837dc5f74d",
    path: "/product/kanape-s-buzheninoy-perepelinym-yaytsom-i-marinovannymi-ogurchikami",
    meta: {"ID":"2108d828-3d97-4e44-a72f-00837dc5f74d","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupTitle":"Брускетты","GroupLink":"/catalog/bruskety","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|766563a0-82d3-47ce-8d74-aeda00eaca0e|21c32fc0-928b-4b2c-bc4d-78a0475c94db",
    path: "/product/21c32fc0-928b-4b2c-bc4d-78a0475c94db",
    meta: {"ID":"21c32fc0-928b-4b2c-bc4d-78a0475c94db","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|22bf8bc0-3383-438f-a700-ddce9526714c",
    path: "/product/spaysi-losos-zapecheniy",
    meta: {"ID":"22bf8bc0-3383-438f-a700-ddce9526714c","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b1a92c89-deb6-45a8-95d4-b12300a986e5|22c18867-bf95-49cc-8a48-29bca466e458",
    path: "/product/22c18867-bf95-49cc-8a48-29bca466e458",
    meta: {"ID":"22c18867-bf95-49cc-8a48-29bca466e458","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|22faf8d8-2c5e-4029-8c5c-0c42bf9958fd",
    path: "/product/gavayskaya-pizza",
    meta: {"ID":"22faf8d8-2c5e-4029-8c5c-0c42bf9958fd","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea|239f781f-6a08-4a68-9b3b-225dfd97bc6b",
    path: "/product/tartaletki-frukty-v-slivochnom-syre",
    meta: {"ID":"239f781f-6a08-4a68-9b3b-225dfd97bc6b","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea","GroupTitle":"Тарталетки","GroupLink":"/catalog/tartaletki","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|23edc9f2-4462-4519-9d0b-11bb33845344",
    path: "/product/darvin-pizza",
    meta: {"ID":"23edc9f2-4462-4519-9d0b-11bb33845344","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|23f7ee6b-b906-475c-abcf-bbe950c9c9ab",
    path: "/product/krevetka-orandzh",
    meta: {"ID":"23f7ee6b-b906-475c-abcf-bbe950c9c9ab","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|766563a0-82d3-47ce-8d74-aeda00eaca0e|2406eebd-05d9-4dda-852a-4592debafe2a",
    path: "/product/2406eebd-05d9-4dda-852a-4592debafe2a",
    meta: {"ID":"2406eebd-05d9-4dda-852a-4592debafe2a","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|6e7b5518-1053-459c-b5a3-af4100e646fd|25531f7d-8e07-4ad2-9446-014e06302164",
    path: "/product/25531f7d-8e07-4ad2-9446-014e06302164",
    meta: {"ID":"25531f7d-8e07-4ad2-9446-014e06302164","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|8899d7d0-89e7-4532-99ef-b02800d16bfd|26846a92-9a9a-4f72-ba35-02061a2d559b",
    path: "/product/26846a92-9a9a-4f72-ba35-02061a2d559b",
    meta: {"ID":"26846a92-9a9a-4f72-ba35-02061a2d559b","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|26864a91-bc53-4c24-9c72-c44ff6339d56",
    path: "/product/26864a91-bc53-4c24-9c72-c44ff6339d56",
    meta: {"ID":"26864a91-bc53-4c24-9c72-c44ff6339d56","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|26ccb682-0a7a-47c6-aceb-52b973abebde",
    path: "/product/26ccb682-0a7a-47c6-aceb-52b973abebde",
    meta: {"ID":"26ccb682-0a7a-47c6-aceb-52b973abebde","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bf7a754f-8fd7-4792-8ffa-af6600904e6a|2822a71f-d5ef-42b1-8875-adbe8d0b5e02",
    path: "/product/chiken-spaysi",
    meta: {"ID":"2822a71f-d5ef-42b1-8875-adbe8d0b5e02","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|28f82217-6f10-4a28-9d2b-3e746b5f7417",
    path: "/product/28f82217-6f10-4a28-9d2b-3e746b5f7417",
    meta: {"ID":"28f82217-6f10-4a28-9d2b-3e746b5f7417","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bc37d11b-8dce-49ce-a28f-b12a00858730|2946398c-3e75-4481-92c5-01290a378d24",
    path: "/product/2946398c-3e75-4481-92c5-01290a378d24",
    meta: {"ID":"2946398c-3e75-4481-92c5-01290a378d24","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|77c22e69-2462-4bd5-9240-b0e000cca68b|29f650ca-af9a-4ef5-bf11-c49b6b5b70a8",
    path: "/product/seld-pod-shuboy",
    meta: {"ID":"29f650ca-af9a-4ef5-bf11-c49b6b5b70a8","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"77c22e69-2462-4bd5-9240-b0e000cca68b","GroupTitle":"Салат","GroupLink":"/catalog/salat","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|2a6aab0b-e8b9-4879-8094-aa27b6b9752a",
    path: "/product/pizza-rimskaya-gavayskaya",
    meta: {"ID":"2a6aab0b-e8b9-4879-8094-aa27b6b9752a","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|2a6b44c4-62f1-4ae6-8db5-2bf28a2797a1",
    path: "/product/2a6b44c4-62f1-4ae6-8db5-2bf28a2797a1",
    meta: {"ID":"2a6b44c4-62f1-4ae6-8db5-2bf28a2797a1","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7fdabbb4-f2fe-4639-9608-ae2800938ea0|2acf59f9-51b1-411c-a227-c3b5da8fb693",
    path: "/product/burger-zavtrak-bif",
    meta: {"ID":"2acf59f9-51b1-411c-a227-c3b5da8fb693","Title":"","GroupID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","SubgroupID":null,"GroupTitle":"Бургеры","GroupLink":"/catalog/burgery","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|2aeaa590-de70-4540-b420-072fd6585cb5",
    path: "/product/borsch-b-p",
    meta: {"ID":"2aeaa590-de70-4540-b420-072fd6585cb5","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b1a92c89-deb6-45a8-95d4-b12300a986e5|2b9d6757-6846-44fa-b631-d2c0dc601067",
    path: "/product/2b9d6757-6846-44fa-b631-d2c0dc601067",
    meta: {"ID":"2b9d6757-6846-44fa-b631-d2c0dc601067","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|2c243838-c656-41d4-9212-e8c990c71d4a",
    path: "/product/2c243838-c656-41d4-9212-e8c990c71d4a",
    meta: {"ID":"2c243838-c656-41d4-9212-e8c990c71d4a","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|2c98116a-3f16-4c96-b5e0-39c7a7b5ea20",
    path: "/product/2c98116a-3f16-4c96-b5e0-39c7a7b5ea20",
    meta: {"ID":"2c98116a-3f16-4c96-b5e0-39c7a7b5ea20","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bc37d11b-8dce-49ce-a28f-b12a00858730|2d24b9e7-b5e3-4c9b-8421-2a3198895917",
    path: "/product/2d24b9e7-b5e3-4c9b-8421-2a3198895917",
    meta: {"ID":"2d24b9e7-b5e3-4c9b-8421-2a3198895917","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|2f9f5343-c66d-4b53-a4e0-71c0898bfe8b",
    path: "/product/2f9f5343-c66d-4b53-a4e0-71c0898bfe8b",
    meta: {"ID":"2f9f5343-c66d-4b53-a4e0-71c0898bfe8b","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|4d1fb455-9ec9-4c99-91e2-b0fb00e2827b|2ffbd12e-1640-49c0-aede-3b88bd255e59",
    path: "/product/2ffbd12e-1640-49c0-aede-3b88bd255e59",
    meta: {"ID":"2ffbd12e-1640-49c0-aede-3b88bd255e59","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|30747575-6671-4be6-8e2d-0b6639865673",
    path: "/product/pizza-rimskaya-po-derevenski",
    meta: {"ID":"30747575-6671-4be6-8e2d-0b6639865673","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|30e2c66e-4db9-4306-af46-43dee8b7f67b",
    path: "/product/30e2c66e-4db9-4306-af46-43dee8b7f67b",
    meta: {"ID":"30e2c66e-4db9-4306-af46-43dee8b7f67b","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|31305d55-edca-4a4c-9746-bcbb6f2bdfd7",
    path: "/product/31305d55-edca-4a4c-9746-bcbb6f2bdfd7",
    meta: {"ID":"31305d55-edca-4a4c-9746-bcbb6f2bdfd7","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|3175f024-cfa8-4de7-99c6-8c693a331709",
    path: "/product/3175f024-cfa8-4de7-99c6-8c693a331709",
    meta: {"ID":"3175f024-cfa8-4de7-99c6-8c693a331709","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|998d3729-dd15-45c9-aa7d-b0c10086fc3e|31817eac-1428-4da1-9197-72c318ad70e7",
    path: "/product/31817eac-1428-4da1-9197-72c318ad70e7",
    meta: {"ID":"31817eac-1428-4da1-9197-72c318ad70e7","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|3253066b-4011-4924-b11b-1fc922e62bbd",
    path: "/product/3253066b-4011-4924-b11b-1fc922e62bbd",
    meta: {"ID":"3253066b-4011-4924-b11b-1fc922e62bbd","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|32650ace-30bd-40d5-a088-62aba352f97a",
    path: "/product/32650ace-30bd-40d5-a088-62aba352f97a",
    meta: {"ID":"32650ace-30bd-40d5-a088-62aba352f97a","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|e0bf81c8-afe8-4300-a2d7-b02800d1792b|34b89ffc-79f1-4891-a095-55229c1ca9b4",
    path: "/product/34b89ffc-79f1-4891-a095-55229c1ca9b4",
    meta: {"ID":"34b89ffc-79f1-4891-a095-55229c1ca9b4","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|1ccdeee5-60bf-4184-ba52-b0e000ccf59b|34fbace4-f41d-403e-95a4-325242590992",
    path: "/product/mini-brusketty-s-krevetkoy-slivochnym-syrom-i-olivkoy",
    meta: {"ID":"34fbace4-f41d-403e-95a4-325242590992","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupTitle":"Брускетты","GroupLink":"/catalog/bruskety","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|35138dbf-59ce-453f-9fc7-26746ea4bfc8",
    path: "/product/35138dbf-59ce-453f-9fc7-26746ea4bfc8",
    meta: {"ID":"35138dbf-59ce-453f-9fc7-26746ea4bfc8","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b2c1dfa9-a3c8-4855-aa82-b02800d16650|3551e915-0c91-4f68-9ce3-d6b8832e0168",
    path: "/product/krabovy-b-p",
    meta: {"ID":"3551e915-0c91-4f68-9ce3-d6b8832e0168","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|362940da-095a-454d-a798-3b9031c9c63f",
    path: "/product/roll-filadelfiya-layt",
    meta: {"ID":"362940da-095a-454d-a798-3b9031c9c63f","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|59107310-8498-4df1-b330-af6500eb304e|36a6211f-8cf0-4e5c-b621-b5dc5270b6fa",
    path: "/product/36a6211f-8cf0-4e5c-b621-b5dc5270b6fa",
    meta: {"ID":"36a6211f-8cf0-4e5c-b621-b5dc5270b6fa","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|36d922b5-be86-4268-bd88-2e6b7f55db64",
    path: "/product/36d922b5-be86-4268-bd88-2e6b7f55db64",
    meta: {"ID":"36d922b5-be86-4268-bd88-2e6b7f55db64","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|37376731-7605-4708-b148-957b71844a50",
    path: "/product/37376731-7605-4708-b148-957b71844a50",
    meta: {"ID":"37376731-7605-4708-b148-957b71844a50","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|397bce68-56fd-4fdb-abd4-f14c212615d3",
    path: "/product/ranch-pizza",
    meta: {"ID":"397bce68-56fd-4fdb-abd4-f14c212615d3","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|39bc2122-448e-42f3-8b82-c8035dc9f5a4",
    path: "/product/pizza-rimskaya-margarita",
    meta: {"ID":"39bc2122-448e-42f3-8b82-c8035dc9f5a4","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|e0bf81c8-afe8-4300-a2d7-b02800d1792b|3a528db5-17ae-49de-9317-b74c6abd95c3",
    path: "/product/3a528db5-17ae-49de-9317-b74c6abd95c3",
    meta: {"ID":"3a528db5-17ae-49de-9317-b74c6abd95c3","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|3acb3d14-5092-43cb-95bd-94dc45cf26dc",
    path: "/product/filadelfiya-klassik",
    meta: {"ID":"3acb3d14-5092-43cb-95bd-94dc45cf26dc","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|3b456ed7-2a9e-476e-a274-1d96cfc3cfb0",
    path: "/product/pizza-s-kopchenym-syrom",
    meta: {"ID":"3b456ed7-2a9e-476e-a274-1d96cfc3cfb0","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|e0bf81c8-afe8-4300-a2d7-b02800d1792b|3b7a2782-5cf3-48c0-b5f2-f1f103a00970",
    path: "/product/3b7a2782-5cf3-48c0-b5f2-f1f103a00970",
    meta: {"ID":"3b7a2782-5cf3-48c0-b5f2-f1f103a00970","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|3cd2addd-3cbb-4999-95b5-cca102fe9904",
    path: "/product/3cd2addd-3cbb-4999-95b5-cca102fe9904",
    meta: {"ID":"3cd2addd-3cbb-4999-95b5-cca102fe9904","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|59107310-8498-4df1-b330-af6500eb304e|3cfec115-2b61-4fe0-a74e-390548956a61",
    path: "/product/3cfec115-2b61-4fe0-a74e-390548956a61",
    meta: {"ID":"3cfec115-2b61-4fe0-a74e-390548956a61","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|3e49eea7-e625-4a9a-a1be-7793157ade22",
    path: "/product/set-khit",
    meta: {"ID":"3e49eea7-e625-4a9a-a1be-7793157ade22","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b2c1dfa9-a3c8-4855-aa82-b02800d16650|3ee263a7-aa03-4e83-beb3-2a926ef92690",
    path: "/product/salat-s-kuritsey-i-ananasom-s",
    meta: {"ID":"3ee263a7-aa03-4e83-beb3-2a926ef92690","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|3efde138-1417-4a78-b268-8bd3e5e04266",
    path: "/product/3efde138-1417-4a78-b268-8bd3e5e04266",
    meta: {"ID":"3efde138-1417-4a78-b268-8bd3e5e04266","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|3f5150a7-b5eb-413f-8d9a-9f20c13765ec",
    path: "/product/pizza-zavtrak",
    meta: {"ID":"3f5150a7-b5eb-413f-8d9a-9f20c13765ec","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7a725ed6-7233-47a5-a705-aeb400a2433d|3f6deef5-35ff-4a6d-8091-850ab0cefba2",
    path: "/product/3f6deef5-35ff-4a6d-8091-850ab0cefba2",
    meta: {"ID":"3f6deef5-35ff-4a6d-8091-850ab0cefba2","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bc37d11b-8dce-49ce-a28f-b12a00858730|3feeaa1f-d6dd-4c63-8f1b-b1f4a3d7ff74",
    path: "/product/3feeaa1f-d6dd-4c63-8f1b-b1f4a3d7ff74",
    meta: {"ID":"3feeaa1f-d6dd-4c63-8f1b-b1f4a3d7ff74","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|40311bd2-9cf5-4a47-a485-e564230a6600",
    path: "/product/40311bd2-9cf5-4a47-a485-e564230a6600",
    meta: {"ID":"40311bd2-9cf5-4a47-a485-e564230a6600","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|40854962-8dc1-4da7-8df2-5b4ffee1a7e0",
    path: "/product/pizza-rimskaya-pepperoni",
    meta: {"ID":"40854962-8dc1-4da7-8df2-5b4ffee1a7e0","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|998d3729-dd15-45c9-aa7d-b0c10086fc3e|41ca41e5-20b4-4f42-b426-a331c0171576",
    path: "/product/41ca41e5-20b4-4f42-b426-a331c0171576",
    meta: {"ID":"41ca41e5-20b4-4f42-b426-a331c0171576","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b1a92c89-deb6-45a8-95d4-b12300a986e5|41cfa30b-26fd-4b6e-87ea-37ee930d57c1",
    path: "/product/41cfa30b-26fd-4b6e-87ea-37ee930d57c1",
    meta: {"ID":"41cfa30b-26fd-4b6e-87ea-37ee930d57c1","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2ba54d74-f42e-4df4-8d69-b0e600cba595|4210c702-b448-45d6-b4b8-fbe612113930",
    path: "/product/mini-lyulya-kebab-iz-kuritsy-1-kg",
    meta: {"ID":"4210c702-b448-45d6-b4b8-fbe612113930","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"2ba54d74-f42e-4df4-8d69-b0e600cba595","GroupTitle":"Горячие закуски","GroupLink":"/catalog/goryachie-zakuski","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|42aea754-f54d-47ab-96c3-d08171dc5d42",
    path: "/product/pizza-vetchina-griby-v",
    meta: {"ID":"42aea754-f54d-47ab-96c3-d08171dc5d42","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|446989c7-34d6-422d-a7b4-d1277d77a609",
    path: "/product/446989c7-34d6-422d-a7b4-d1277d77a609",
    meta: {"ID":"446989c7-34d6-422d-a7b4-d1277d77a609","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|4544f988-154c-46bd-8c2f-a0ac55b28dde",
    path: "/product/krevetka-krab-v-klyare",
    meta: {"ID":"4544f988-154c-46bd-8c2f-a0ac55b28dde","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|45d1ce87-ad22-410a-a6a1-6dad8953e7f9",
    path: "/product/45d1ce87-ad22-410a-a6a1-6dad8953e7f9",
    meta: {"ID":"45d1ce87-ad22-410a-a6a1-6dad8953e7f9","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|327b4329-2465-4bfc-b424-af660086214b|47bcc007-c657-49ed-af78-30f099d13dff",
    path: "/product/47bcc007-c657-49ed-af78-30f099d13dff",
    meta: {"ID":"47bcc007-c657-49ed-af78-30f099d13dff","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|065cfcd4-7eab-4818-ad12-b0e000cccc3c|48c14e89-444c-4349-9b53-cb612c214cf3",
    path: "/product/kanape-krevetka-s-avokado",
    meta: {"ID":"48c14e89-444c-4349-9b53-cb612c214cf3","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"065cfcd4-7eab-4818-ad12-b0e000cccc3c","GroupTitle":"Канапе","GroupLink":"/catalog/kanape","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7fdabbb4-f2fe-4639-9608-ae2800938ea0|48eb7fc4-9837-4880-b03f-1c023dc67b78",
    path: "/product/burger-zavtrak-b",
    meta: {"ID":"48eb7fc4-9837-4880-b03f-1c023dc67b78","Title":"","GroupID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","SubgroupID":null,"GroupTitle":"Бургеры","GroupLink":"/catalog/burgery","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b1a92c89-deb6-45a8-95d4-b12300a986e5|49527e27-da57-4ec6-a60c-7a85c89caa0d",
    path: "/product/49527e27-da57-4ec6-a60c-7a85c89caa0d",
    meta: {"ID":"49527e27-da57-4ec6-a60c-7a85c89caa0d","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|4a0e7c34-d6ab-4f07-934d-9e7f110b2128",
    path: "/product/4a0e7c34-d6ab-4f07-934d-9e7f110b2128",
    meta: {"ID":"4a0e7c34-d6ab-4f07-934d-9e7f110b2128","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7fdabbb4-f2fe-4639-9608-ae2800938ea0|4acb0557-9614-492d-ac6b-39627e5b1bed",
    path: "/product/chizburger",
    meta: {"ID":"4acb0557-9614-492d-ac6b-39627e5b1bed","Title":"","GroupID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","SubgroupID":null,"GroupTitle":"Бургеры","GroupLink":"/catalog/burgery","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|4b324029-6fab-4218-ae23-b088234b3d99",
    path: "/product/roll-atlanticheskiy",
    meta: {"ID":"4b324029-6fab-4218-ae23-b088234b3d99","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b2c1dfa9-a3c8-4855-aa82-b02800d16650|4b7bf03a-d371-4560-8f85-6cef94df7821",
    path: "/product/vesenniy-s",
    meta: {"ID":"4b7bf03a-d371-4560-8f85-6cef94df7821","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|4bfbf31d-75de-43e7-b33c-fb01eff313e7",
    path: "/product/pizza-rimskaya-ranch",
    meta: {"ID":"4bfbf31d-75de-43e7-b33c-fb01eff313e7","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|4c13c76d-a33e-466b-a671-bb222391b428",
    path: "/product/4c13c76d-a33e-466b-a671-bb222391b428",
    meta: {"ID":"4c13c76d-a33e-466b-a671-bb222391b428","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|77c22e69-2462-4bd5-9240-b0e000cca68b|4c2dbe41-c600-4a1e-b63f-6ff24d094eab",
    path: "/product/salat-vinegret-1-kg",
    meta: {"ID":"4c2dbe41-c600-4a1e-b63f-6ff24d094eab","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"77c22e69-2462-4bd5-9240-b0e000cca68b","GroupTitle":"Салат","GroupLink":"/catalog/salat","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|4e94315d-4f9c-420b-b847-5aeb8f5533fa",
    path: "/product/losos-oranzhd",
    meta: {"ID":"4e94315d-4f9c-420b-b847-5aeb8f5533fa","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|4ee25c27-7a72-4eaa-9d5a-58353f5a8dac",
    path: "/product/4ee25c27-7a72-4eaa-9d5a-58353f5a8dac",
    meta: {"ID":"4ee25c27-7a72-4eaa-9d5a-58353f5a8dac","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|4f5af732-b288-4e9e-b64e-a5956f4b570d",
    path: "/product/4f5af732-b288-4e9e-b64e-a5956f4b570d",
    meta: {"ID":"4f5af732-b288-4e9e-b64e-a5956f4b570d","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|4f68b168-1a50-4949-ab0a-a27593510462",
    path: "/product/4f68b168-1a50-4949-ab0a-a27593510462",
    meta: {"ID":"4f68b168-1a50-4949-ab0a-a27593510462","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|77c22e69-2462-4bd5-9240-b0e000cca68b|4f8f8d73-d9b0-4779-bd5f-11de76db2a75",
    path: "/product/salat-s-kuritsey-i-ananasom-1-kg",
    meta: {"ID":"4f8f8d73-d9b0-4779-bd5f-11de76db2a75","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"77c22e69-2462-4bd5-9240-b0e000cca68b","GroupTitle":"Салат","GroupLink":"/catalog/salat","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|5073af24-876f-4665-8d11-2dce07173e26",
    path: "/product/5073af24-876f-4665-8d11-2dce07173e26",
    meta: {"ID":"5073af24-876f-4665-8d11-2dce07173e26","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|513dce5b-70ae-4a86-9e3c-841434a8244a",
    path: "/product/513dce5b-70ae-4a86-9e3c-841434a8244a",
    meta: {"ID":"513dce5b-70ae-4a86-9e3c-841434a8244a","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|513fcc3e-4950-499c-a21b-c57220336954",
    path: "/product/513fcc3e-4950-499c-a21b-c57220336954",
    meta: {"ID":"513fcc3e-4950-499c-a21b-c57220336954","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|4d1fb455-9ec9-4c99-91e2-b0fb00e2827b|5172f274-39ad-4727-a2de-37adb25db5c7",
    path: "/product/5172f274-39ad-4727-a2de-37adb25db5c7",
    meta: {"ID":"5172f274-39ad-4727-a2de-37adb25db5c7","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|766563a0-82d3-47ce-8d74-aeda00eaca0e|51869ac3-8ea2-4e0c-97f8-d51b39280867",
    path: "/product/51869ac3-8ea2-4e0c-97f8-d51b39280867",
    meta: {"ID":"51869ac3-8ea2-4e0c-97f8-d51b39280867","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|53f5fc73-86b7-44cb-a2d3-79b0a8bdcdb4",
    path: "/product/53f5fc73-86b7-44cb-a2d3-79b0a8bdcdb4",
    meta: {"ID":"53f5fc73-86b7-44cb-a2d3-79b0a8bdcdb4","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|54a5ecd9-2ed0-4f82-8c68-55add2354cb5",
    path: "/product/pizza-rimskaya-syrniy-tsyplenok",
    meta: {"ID":"54a5ecd9-2ed0-4f82-8c68-55add2354cb5","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b4759fa8-c656-47bb-9006-af6600df98ee|566f3707-2b73-4a88-a2cc-24a5401b6bad",
    path: "/product/566f3707-2b73-4a88-a2cc-24a5401b6bad",
    meta: {"ID":"566f3707-2b73-4a88-a2cc-24a5401b6bad","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|56c3310b-e081-4dd9-94c9-3cbc2de24def",
    path: "/product/56c3310b-e081-4dd9-94c9-3cbc2de24def",
    meta: {"ID":"56c3310b-e081-4dd9-94c9-3cbc2de24def","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|5761f0ad-52ea-46f2-a8a9-93e596cdb1f8",
    path: "/product/5761f0ad-52ea-46f2-a8a9-93e596cdb1f8",
    meta: {"ID":"5761f0ad-52ea-46f2-a8a9-93e596cdb1f8","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|58310861-0131-43c4-a1db-d5b277e18dfe",
    path: "/product/goryachiy-okun",
    meta: {"ID":"58310861-0131-43c4-a1db-d5b277e18dfe","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|59347c23-650e-4ba3-92ae-274709c903e6",
    path: "/product/59347c23-650e-4ba3-92ae-274709c903e6",
    meta: {"ID":"59347c23-650e-4ba3-92ae-274709c903e6","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|e0bf81c8-afe8-4300-a2d7-b02800d1792b|59734003-6e18-405a-878d-f7948605a5b4",
    path: "/product/59734003-6e18-405a-878d-f7948605a5b4",
    meta: {"ID":"59734003-6e18-405a-878d-f7948605a5b4","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7a725ed6-7233-47a5-a705-aeb400a2433d|59766ae0-dda3-4d5e-930a-47587284faff",
    path: "/product/59766ae0-dda3-4d5e-930a-47587284faff",
    meta: {"ID":"59766ae0-dda3-4d5e-930a-47587284faff","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|065cfcd4-7eab-4818-ad12-b0e000cccc3c|5976e1ef-3552-48c2-bbf8-83f015fe0f9b",
    path: "/product/kanape-pepperoni-s-fettoy-i-ovoschami",
    meta: {"ID":"5976e1ef-3552-48c2-bbf8-83f015fe0f9b","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"065cfcd4-7eab-4818-ad12-b0e000cccc3c","GroupTitle":"Канапе","GroupLink":"/catalog/kanape","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|6ad09738-0b2a-4cb3-84d3-b0f2008e75d8|5995f793-d199-41c7-b714-5073c04b1993",
    path: "/product/blinchiki-s-tvorogom",
    meta: {"ID":"5995f793-d199-41c7-b714-5073c04b1993","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|59a450af-bc98-452c-b808-881559b691af",
    path: "/product/59a450af-bc98-452c-b808-881559b691af",
    meta: {"ID":"59a450af-bc98-452c-b808-881559b691af","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|59bb82b9-6e9b-4f1b-ad19-d05bcdfa8414",
    path: "/product/59bb82b9-6e9b-4f1b-ad19-d05bcdfa8414",
    meta: {"ID":"59bb82b9-6e9b-4f1b-ad19-d05bcdfa8414","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|5a62e391-0ba2-49b5-82df-4ecb7ca2c72f",
    path: "/product/Lyulya-kebab-iz-kuritsy-v",
    meta: {"ID":"5a62e391-0ba2-49b5-82df-4ecb7ca2c72f","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|5b3f076a-eb11-49bb-bfd0-6a288936a19c",
    path: "/product/5b3f076a-eb11-49bb-bfd0-6a288936a19c",
    meta: {"ID":"5b3f076a-eb11-49bb-bfd0-6a288936a19c","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|5c7b1233-fb8c-4a6c-a22c-ca4b3719a798",
    path: "/product/5c7b1233-fb8c-4a6c-a22c-ca4b3719a798",
    meta: {"ID":"5c7b1233-fb8c-4a6c-a22c-ca4b3719a798","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b1a92c89-deb6-45a8-95d4-b12300a986e5|5dfe3fd3-5427-440a-af7c-e2ff396bdd46",
    path: "/product/5dfe3fd3-5427-440a-af7c-e2ff396bdd46",
    meta: {"ID":"5dfe3fd3-5427-440a-af7c-e2ff396bdd46","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7a725ed6-7233-47a5-a705-aeb400a2433d|5e3c5022-9b01-4e27-bb40-674366545350",
    path: "/product/5e3c5022-9b01-4e27-bb40-674366545350",
    meta: {"ID":"5e3c5022-9b01-4e27-bb40-674366545350","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|5e5b550e-c486-42bc-a910-5011f9a7676c",
    path: "/product/zhulen-pizza",
    meta: {"ID":"5e5b550e-c486-42bc-a910-5011f9a7676c","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|5e6133bf-b3f3-4b5a-bd92-babad59f3be7",
    path: "/product/karbonara-pizza",
    meta: {"ID":"5e6133bf-b3f3-4b5a-bd92-babad59f3be7","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|5ed22518-c5bd-4af5-802e-bfb2a1f39281",
    path: "/product/5ed22518-c5bd-4af5-802e-bfb2a1f39281",
    meta: {"ID":"5ed22518-c5bd-4af5-802e-bfb2a1f39281","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|5edf6e11-1f6f-44e3-9726-e42e740f4012",
    path: "/product/5edf6e11-1f6f-44e3-9726-e42e740f4012",
    meta: {"ID":"5edf6e11-1f6f-44e3-9726-e42e740f4012","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|5efb9f51-becd-4a20-8a5c-ddd11187109f",
    path: "/product/pitstsa-soberi-sam-30sm",
    meta: {"ID":"5efb9f51-becd-4a20-8a5c-ddd11187109f","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","AdditionalSeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|5fa3d774-82d3-42fc-a41d-5a286a9e985f",
    path: "/product/5fa3d774-82d3-42fc-a41d-5a286a9e985f",
    meta: {"ID":"5fa3d774-82d3-42fc-a41d-5a286a9e985f","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|5fb08d34-e2f3-4dfb-836a-8cac46cfc821",
    path: "/product/pizza-rimskaya-s-kopchenym-syrom",
    meta: {"ID":"5fb08d34-e2f3-4dfb-836a-8cac46cfc821","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|606dede4-4834-4607-b567-d2b0bf825ceb",
    path: "/product/606dede4-4834-4607-b567-d2b0bf825ceb",
    meta: {"ID":"606dede4-4834-4607-b567-d2b0bf825ceb","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|60a2e459-baad-45f1-93c9-7e68af5d041c",
    path: "/product/60a2e459-baad-45f1-93c9-7e68af5d041c",
    meta: {"ID":"60a2e459-baad-45f1-93c9-7e68af5d041c","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b1a92c89-deb6-45a8-95d4-b12300a986e5|61b75dec-a1ee-46e6-91fd-8273885f9ca8",
    path: "/product/61b75dec-a1ee-46e6-91fd-8273885f9ca8",
    meta: {"ID":"61b75dec-a1ee-46e6-91fd-8273885f9ca8","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|61c606c5-1796-487a-813c-b19eeb30a1da",
    path: "/product/61c606c5-1796-487a-813c-b19eeb30a1da",
    meta: {"ID":"61c606c5-1796-487a-813c-b19eeb30a1da","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|766563a0-82d3-47ce-8d74-aeda00eaca0e|633225df-2485-4251-9b6c-d67b0e9571c0",
    path: "/product/633225df-2485-4251-9b6c-d67b0e9571c0",
    meta: {"ID":"633225df-2485-4251-9b6c-d67b0e9571c0","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|63d18f86-5334-4b74-83f9-c832c8e7d45b",
    path: "/product/63d18f86-5334-4b74-83f9-c832c8e7d45b",
    meta: {"ID":"63d18f86-5334-4b74-83f9-c832c8e7d45b","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|653387c8-73e1-4010-9631-b32828654ee6",
    path: "/product/653387c8-73e1-4010-9631-b32828654ee6",
    meta: {"ID":"653387c8-73e1-4010-9631-b32828654ee6","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|653b71fb-d48d-4faf-9ab0-534fa6a562a8",
    path: "/product/skovorodka-s-moreproduktami",
    meta: {"ID":"653b71fb-d48d-4faf-9ab0-534fa6a562a8","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|654e2ca6-e81b-438a-9766-694ee500811f",
    path: "/product/654e2ca6-e81b-438a-9766-694ee500811f",
    meta: {"ID":"654e2ca6-e81b-438a-9766-694ee500811f","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|6571c0ad-a747-426d-ab47-1965e1fd530e",
    path: "/product/6571c0ad-a747-426d-ab47-1965e1fd530e",
    meta: {"ID":"6571c0ad-a747-426d-ab47-1965e1fd530e","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|8899d7d0-89e7-4532-99ef-b02800d16bfd|6594cf32-3c40-4aa2-bf02-723937d6a92c",
    path: "/product/tom-yam-s-kuritsey-lanch",
    meta: {"ID":"6594cf32-3c40-4aa2-bf02-723937d6a92c","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|66650b21-6f7b-4a5c-92e1-eb3d64e9e2f6",
    path: "/product/pizza-rimskaya-4-syra",
    meta: {"ID":"66650b21-6f7b-4a5c-92e1-eb3d64e9e2f6","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|66b51665-8650-46f1-8894-0e319a60674c",
    path: "/product/66b51665-8650-46f1-8894-0e319a60674c",
    meta: {"ID":"66b51665-8650-46f1-8894-0e319a60674c","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea|66f6db34-ea47-49f5-98b2-932f077ece62",
    path: "/product/tartaletki-s-kopchenoy-kuritsey-i-ananasom",
    meta: {"ID":"66f6db34-ea47-49f5-98b2-932f077ece62","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea","GroupTitle":"Тарталетки","GroupLink":"/catalog/tartaletki","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|6707653e-9e73-4dff-b712-d2ab1378c5a2",
    path: "/product/kaliforniya-s-krab-miksom",
    meta: {"ID":"6707653e-9e73-4dff-b712-d2ab1378c5a2","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|065cfcd4-7eab-4818-ad12-b0e000cccc3c|6736d290-4184-4ee9-8596-375aee79a7aa",
    path: "/product/kanape-cherri-s-slivochnym-syrom-i-olivkami",
    meta: {"ID":"6736d290-4184-4ee9-8596-375aee79a7aa","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"065cfcd4-7eab-4818-ad12-b0e000cccc3c","GroupTitle":"Канапе","GroupLink":"/catalog/kanape","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|998d3729-dd15-45c9-aa7d-b0c10086fc3e|673e126e-83d4-469f-9485-a0e59b9b8263",
    path: "/product/673e126e-83d4-469f-9485-a0e59b9b8263",
    meta: {"ID":"673e126e-83d4-469f-9485-a0e59b9b8263","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|6761de35-c7e5-4eef-b009-0a1e1c30014d",
    path: "/product/6761de35-c7e5-4eef-b009-0a1e1c30014d",
    meta: {"ID":"6761de35-c7e5-4eef-b009-0a1e1c30014d","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|353b1044-377a-4afe-8983-ae0600ca36e6|67ef16c4-879c-4cbb-b2fe-9ca807d3be10",
    path: "/product/67ef16c4-879c-4cbb-b2fe-9ca807d3be10",
    meta: {"ID":"67ef16c4-879c-4cbb-b2fe-9ca807d3be10","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|680b0097-d624-4287-9f0c-3021c47cb482",
    path: "/product/680b0097-d624-4287-9f0c-3021c47cb482",
    meta: {"ID":"680b0097-d624-4287-9f0c-3021c47cb482","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|68490a00-0ea1-4ee0-9bf9-77ca23113c09",
    path: "/product/zapechenniy-krab",
    meta: {"ID":"68490a00-0ea1-4ee0-9bf9-77ca23113c09","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|1ccdeee5-60bf-4184-ba52-b0e000ccf59b|688f687a-6a6a-4116-a405-a32670ffefdd",
    path: "/product/mini-brusketty-po-grecheski",
    meta: {"ID":"688f687a-6a6a-4116-a405-a32670ffefdd","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupTitle":"Брускетты","GroupLink":"/catalog/bruskety","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|692ecad8-7f9d-430b-8d0c-3fabe915ab6c",
    path: "/product/set-uikend",
    meta: {"ID":"692ecad8-7f9d-430b-8d0c-3fabe915ab6c","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|6935484e-c77b-4344-b8fe-65ad37bbe472",
    path: "/product/6935484e-c77b-4344-b8fe-65ad37bbe472",
    meta: {"ID":"6935484e-c77b-4344-b8fe-65ad37bbe472","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|6a92637b-ccae-4e12-9009-7845e00387b1",
    path: "/product/6a92637b-ccae-4e12-9009-7845e00387b1",
    meta: {"ID":"6a92637b-ccae-4e12-9009-7845e00387b1","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|6bb6d05b-b450-4ba5-8221-6b3dafad3e98",
    path: "/product/6bb6d05b-b450-4ba5-8221-6b3dafad3e98",
    meta: {"ID":"6bb6d05b-b450-4ba5-8221-6b3dafad3e98","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|6d144037-c377-41fb-905b-1671a702b5ec",
    path: "/product/6d144037-c377-41fb-905b-1671a702b5ec",
    meta: {"ID":"6d144037-c377-41fb-905b-1671a702b5ec","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|6dbfb241-7c7a-4046-948a-c587c09cf4bc",
    path: "/product/6dbfb241-7c7a-4046-948a-c587c09cf4bc",
    meta: {"ID":"6dbfb241-7c7a-4046-948a-c587c09cf4bc","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|f0ef77f3-654b-4e89-ac5b-af660095e2d3|6e6f74e8-c728-4cf7-b5d5-b716a650d83f",
    path: "/product/6e6f74e8-c728-4cf7-b5d5-b716a650d83f",
    meta: {"ID":"6e6f74e8-c728-4cf7-b5d5-b716a650d83f","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|6f05add3-4366-4507-9725-0acf33d37caa",
    path: "/product/6f05add3-4366-4507-9725-0acf33d37caa",
    meta: {"ID":"6f05add3-4366-4507-9725-0acf33d37caa","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|6f452b41-dc86-4b4d-9edf-cff1c9d8041f",
    path: "/product/6f452b41-dc86-4b4d-9edf-cff1c9d8041f",
    meta: {"ID":"6f452b41-dc86-4b4d-9edf-cff1c9d8041f","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b4759fa8-c656-47bb-9006-af6600df98ee|70790d22-92d4-4edf-b970-0c0c8783b3d9",
    path: "/product/70790d22-92d4-4edf-b970-0c0c8783b3d9",
    meta: {"ID":"70790d22-92d4-4edf-b970-0c0c8783b3d9","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|71fd80b5-c783-4d12-8cc0-ab594f585016",
    path: "/product/solyanka-1",
    meta: {"ID":"71fd80b5-c783-4d12-8cc0-ab594f585016","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7fdabbb4-f2fe-4639-9608-ae2800938ea0|723b394c-8b58-43d8-a9a6-5c91b190b254",
    path: "/product/burger-spaysi",
    meta: {"ID":"723b394c-8b58-43d8-a9a6-5c91b190b254","Title":"","GroupID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","SubgroupID":null,"GroupTitle":"Бургеры","GroupLink":"/catalog/burgery","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|72bd263f-358e-4812-8acb-27adce38c6de",
    path: "/product/myasnoe-assorti-pizza",
    meta: {"ID":"72bd263f-358e-4812-8acb-27adce38c6de","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|73230e88-7b48-4581-bac9-6fc0d6615ab9",
    path: "/product/roll-kaliforniya-s-krevetkoy",
    meta: {"ID":"73230e88-7b48-4581-bac9-6fc0d6615ab9","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|8899d7d0-89e7-4532-99ef-b02800d16bfd|74930d51-770b-4186-bec9-747172220926",
    path: "/product/74930d51-770b-4186-bec9-747172220926",
    meta: {"ID":"74930d51-770b-4186-bec9-747172220926","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|766563a0-82d3-47ce-8d74-aeda00eaca0e|74d58a43-ce11-474f-8a65-582caf089f12",
    path: "/product/74d58a43-ce11-474f-8a65-582caf089f12",
    meta: {"ID":"74d58a43-ce11-474f-8a65-582caf089f12","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7fdabbb4-f2fe-4639-9608-ae2800938ea0|74e85dff-b8ac-4f05-8734-6ae5d10a9815",
    path: "/product/burger-zavtrak-chiken",
    meta: {"ID":"74e85dff-b8ac-4f05-8734-6ae5d10a9815","Title":"","GroupID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","SubgroupID":null,"GroupTitle":"Бургеры","GroupLink":"/catalog/burgery","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|5c023689-385b-4718-8278-adfa00fa7f90|75c35103-9491-4792-aa7d-28d420465a81",
    path: "/product/75c35103-9491-4792-aa7d-28d420465a81",
    meta: {"ID":"75c35103-9491-4792-aa7d-28d420465a81","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|75ec23d4-ee76-4b13-8e37-da2ca8fba259",
    path: "/product/kolbaski-kurinye-gril-s-ovoschami",
    meta: {"ID":"75ec23d4-ee76-4b13-8e37-da2ca8fba259","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|765d7476-33c4-4c30-a5f2-90b6abf55cd0",
    path: "/product/765d7476-33c4-4c30-a5f2-90b6abf55cd0",
    meta: {"ID":"765d7476-33c4-4c30-a5f2-90b6abf55cd0","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|77a885be-4804-4f73-9fe3-5e101110db0e",
    path: "/product/77a885be-4804-4f73-9fe3-5e101110db0e",
    meta: {"ID":"77a885be-4804-4f73-9fe3-5e101110db0e","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|77f443c1-95a7-4f37-9a86-0c96ba05de0a",
    path: "/product/set-sotnya",
    meta: {"ID":"77f443c1-95a7-4f37-9a86-0c96ba05de0a","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|78135e32-3a6e-409e-b7b5-63e2ef4895dd",
    path: "/product/78135e32-3a6e-409e-b7b5-63e2ef4895dd",
    meta: {"ID":"78135e32-3a6e-409e-b7b5-63e2ef4895dd","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|7955847a-9e77-473d-8b6e-acdc76bd88e1",
    path: "/product/7955847a-9e77-473d-8b6e-acdc76bd88e1",
    meta: {"ID":"7955847a-9e77-473d-8b6e-acdc76bd88e1","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|59107310-8498-4df1-b330-af6500eb304e|79bf4405-413e-4643-a4c5-bc64152c6d0f",
    path: "/product/79bf4405-413e-4643-a4c5-bc64152c6d0f",
    meta: {"ID":"79bf4405-413e-4643-a4c5-bc64152c6d0f","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|79e43e79-8689-429d-8618-c4d06112e250",
    path: "/product/79e43e79-8689-429d-8618-c4d06112e250",
    meta: {"ID":"79e43e79-8689-429d-8618-c4d06112e250","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|5c023689-385b-4718-8278-adfa00fa7f90|7a4d0ba2-afe8-435f-a539-7dfa3d5acab4",
    path: "/product/7a4d0ba2-afe8-435f-a539-7dfa3d5acab4",
    meta: {"ID":"7a4d0ba2-afe8-435f-a539-7dfa3d5acab4","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b4759fa8-c656-47bb-9006-af6600df98ee|7a891363-436a-44d6-8698-bd66709cbcf7",
    path: "/product/7a891363-436a-44d6-8698-bd66709cbcf7",
    meta: {"ID":"7a891363-436a-44d6-8698-bd66709cbcf7","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|7ca0a319-edeb-45d5-8f08-1e1d190ca5cc",
    path: "/product/s-tsyplenkom-i-syrom-dor-blyu-pizza",
    meta: {"ID":"7ca0a319-edeb-45d5-8f08-1e1d190ca5cc","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","AdditionalSeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|7cc02fe8-1962-4da3-b023-90032504d5ca",
    path: "/product/7cc02fe8-1962-4da3-b023-90032504d5ca",
    meta: {"ID":"7cc02fe8-1962-4da3-b023-90032504d5ca","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|e3de3a35-eaaf-4471-8e1f-b0e000c7f0b0|7cc361e5-406c-477e-9bf1-28632ebe1235",
    path: "/product/7cc361e5-406c-477e-9bf1-28632ebe1235",
    meta: {"ID":"7cc361e5-406c-477e-9bf1-28632ebe1235","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|7d4ce40e-af03-4c2d-b22f-3d37c34ddf70",
    path: "/product/7d4ce40e-af03-4c2d-b22f-3d37c34ddf70",
    meta: {"ID":"7d4ce40e-af03-4c2d-b22f-3d37c34ddf70","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|065cfcd4-7eab-4818-ad12-b0e000cccc3c|7e58107c-2e2f-4274-804b-870b9e05f8a0",
    path: "/product/kanape-salyami-s-syrom-fetta-i-cherri",
    meta: {"ID":"7e58107c-2e2f-4274-804b-870b9e05f8a0","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"065cfcd4-7eab-4818-ad12-b0e000cccc3c","GroupTitle":"Канапе","GroupLink":"/catalog/kanape","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bc37d11b-8dce-49ce-a28f-b12a00858730|7e5a263c-8f01-4c6d-ba82-72078730bea7",
    path: "/product/7e5a263c-8f01-4c6d-ba82-72078730bea7",
    meta: {"ID":"7e5a263c-8f01-4c6d-ba82-72078730bea7","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|7e621761-c1bc-4eef-ad27-0275688eb6a4",
    path: "/product/set-kaliforniya",
    meta: {"ID":"7e621761-c1bc-4eef-ad27-0275688eb6a4","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bc37d11b-8dce-49ce-a28f-b12a00858730|7e86b12c-08ec-43b3-9a72-465d339c0570",
    path: "/product/7e86b12c-08ec-43b3-9a72-465d339c0570",
    meta: {"ID":"7e86b12c-08ec-43b3-9a72-465d339c0570","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|7e95fc2f-a218-4d6a-b59e-af8c9aa79ce9",
    path: "/product/7e95fc2f-a218-4d6a-b59e-af8c9aa79ce9",
    meta: {"ID":"7e95fc2f-a218-4d6a-b59e-af8c9aa79ce9","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|f0ef77f3-654b-4e89-ac5b-af660095e2d3|7f3ce4c9-333e-4859-b0b4-45d52179a186",
    path: "/product/7f3ce4c9-333e-4859-b0b4-45d52179a186",
    meta: {"ID":"7f3ce4c9-333e-4859-b0b4-45d52179a186","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|7fe29d0b-c237-4dc8-bddf-1dd467ece3f2",
    path: "/product/meksikanskaya-pizza",
    meta: {"ID":"7fe29d0b-c237-4dc8-bddf-1dd467ece3f2","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7fdabbb4-f2fe-4639-9608-ae2800938ea0|809f674c-67bf-4d18-8a20-808640ee0a6d",
    path: "/product/burger-govyazhiy",
    meta: {"ID":"809f674c-67bf-4d18-8a20-808640ee0a6d","Title":"","GroupID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","SubgroupID":null,"GroupTitle":"Бургеры","GroupLink":"/catalog/burgery","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|80fe2cd6-1efa-43cc-9057-2a23806e4ab4",
    path: "/product/80fe2cd6-1efa-43cc-9057-2a23806e4ab4",
    meta: {"ID":"80fe2cd6-1efa-43cc-9057-2a23806e4ab4","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|8118791a-3450-45c6-8560-5d549adc3cc9",
    path: "/product/8118791a-3450-45c6-8560-5d549adc3cc9",
    meta: {"ID":"8118791a-3450-45c6-8560-5d549adc3cc9","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|8158b780-8b32-4d24-b455-75d9cde97c06",
    path: "/product/8158b780-8b32-4d24-b455-75d9cde97c06",
    meta: {"ID":"8158b780-8b32-4d24-b455-75d9cde97c06","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|59107310-8498-4df1-b330-af6500eb304e|82f3d9a6-0671-4b20-aa94-c6a11fdc0ec3",
    path: "/product/82f3d9a6-0671-4b20-aa94-c6a11fdc0ec3",
    meta: {"ID":"82f3d9a6-0671-4b20-aa94-c6a11fdc0ec3","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|83109689-9df5-496b-a051-5f27ea7aa440",
    path: "/product/pizza-rimskaya-darvin",
    meta: {"ID":"83109689-9df5-496b-a051-5f27ea7aa440","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b1a92c89-deb6-45a8-95d4-b12300a986e5|83f0f2c3-e746-475c-95e5-7145e4c39312",
    path: "/product/83f0f2c3-e746-475c-95e5-7145e4c39312",
    meta: {"ID":"83f0f2c3-e746-475c-95e5-7145e4c39312","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|87022b65-0859-4c89-bf43-4275e1ba7635",
    path: "/product/87022b65-0859-4c89-bf43-4275e1ba7635",
    meta: {"ID":"87022b65-0859-4c89-bf43-4275e1ba7635","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|87228a44-6ded-4301-8675-18725d941147",
    path: "/product/87228a44-6ded-4301-8675-18725d941147",
    meta: {"ID":"87228a44-6ded-4301-8675-18725d941147","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|88e5d175-266b-4e99-bd35-e191d3f5f90a",
    path: "/product/88e5d175-266b-4e99-bd35-e191d3f5f90a",
    meta: {"ID":"88e5d175-266b-4e99-bd35-e191d3f5f90a","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea|88e5ec0d-3ead-44de-a349-97954afd73d9",
    path: "/product/tartaletki-s-kuritsey-i-gribami-pod-syrom",
    meta: {"ID":"88e5ec0d-3ead-44de-a349-97954afd73d9","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea","GroupTitle":"Тарталетки","GroupLink":"/catalog/tartaletki","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|8924a899-6035-4726-a2df-682dfea4f0e1",
    path: "/product/8924a899-6035-4726-a2df-682dfea4f0e1",
    meta: {"ID":"8924a899-6035-4726-a2df-682dfea4f0e1","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|89d34c20-4044-467c-9ab3-a924824d52a1",
    path: "/product/set-big",
    meta: {"ID":"89d34c20-4044-467c-9ab3-a924824d52a1","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|89d6159b-7404-4214-bd3c-f03d28f5b885",
    path: "/product/89d6159b-7404-4214-bd3c-f03d28f5b885",
    meta: {"ID":"89d6159b-7404-4214-bd3c-f03d28f5b885","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|89e522b9-ca18-4e30-90d3-a6b0c3eebcbe",
    path: "/product/89e522b9-ca18-4e30-90d3-a6b0c3eebcbe",
    meta: {"ID":"89e522b9-ca18-4e30-90d3-a6b0c3eebcbe","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|77c22e69-2462-4bd5-9240-b0e000cca68b|8a13ecfd-6daf-4d2c-a7de-74a47ba37b79",
    path: "/product/salat-tsezar-s-krevetkoy-1-kg",
    meta: {"ID":"8a13ecfd-6daf-4d2c-a7de-74a47ba37b79","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"77c22e69-2462-4bd5-9240-b0e000cca68b","GroupTitle":"Салат","GroupLink":"/catalog/salat","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|8a687311-1b92-4ce0-b0c7-a7b67e75c7a4",
    path: "/product/8a687311-1b92-4ce0-b0c7-a7b67e75c7a4",
    meta: {"ID":"8a687311-1b92-4ce0-b0c7-a7b67e75c7a4","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|8b03d48c-4cc3-4d67-a667-2ad9094dba8f",
    path: "/product/8b03d48c-4cc3-4d67-a667-2ad9094dba8f",
    meta: {"ID":"8b03d48c-4cc3-4d67-a667-2ad9094dba8f","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|8d6e5b5d-0c6c-441b-8444-452a0d9e8c25",
    path: "/product/8d6e5b5d-0c6c-441b-8444-452a0d9e8c25",
    meta: {"ID":"8d6e5b5d-0c6c-441b-8444-452a0d9e8c25","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|9029a0ad-c8ec-451b-a508-223f3e01294d",
    path: "/product/9029a0ad-c8ec-451b-a508-223f3e01294d",
    meta: {"ID":"9029a0ad-c8ec-451b-a508-223f3e01294d","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|e0bf81c8-afe8-4300-a2d7-b02800d1792b|90d11300-5d9b-4dd0-9141-76bcc990a86d",
    path: "/product/90d11300-5d9b-4dd0-9141-76bcc990a86d",
    meta: {"ID":"90d11300-5d9b-4dd0-9141-76bcc990a86d","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|914b9c10-34fd-449e-b94e-328e44620388",
    path: "/product/914b9c10-34fd-449e-b94e-328e44620388",
    meta: {"ID":"914b9c10-34fd-449e-b94e-328e44620388","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|998d3729-dd15-45c9-aa7d-b0c10086fc3e|918ffe01-6645-4a35-8d54-19552c24c21b",
    path: "/product/918ffe01-6645-4a35-8d54-19552c24c21b",
    meta: {"ID":"918ffe01-6645-4a35-8d54-19552c24c21b","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|327b4329-2465-4bfc-b424-af660086214b|9515c9a5-e259-4f09-9ae3-02208efb2613",
    path: "/product/9515c9a5-e259-4f09-9ae3-02208efb2613",
    meta: {"ID":"9515c9a5-e259-4f09-9ae3-02208efb2613","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|95a8d2ac-4010-4263-b170-63be41d646ad",
    path: "/product/95a8d2ac-4010-4263-b170-63be41d646ad",
    meta: {"ID":"95a8d2ac-4010-4263-b170-63be41d646ad","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bc37d11b-8dce-49ce-a28f-b12a00858730|972880a6-3d3c-49df-a5d6-49a67df1858d",
    path: "/product/972880a6-3d3c-49df-a5d6-49a67df1858d",
    meta: {"ID":"972880a6-3d3c-49df-a5d6-49a67df1858d","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|9733632b-8bd0-4e62-adfa-17cc6fe71176",
    path: "/product/9733632b-8bd0-4e62-adfa-17cc6fe71176",
    meta: {"ID":"9733632b-8bd0-4e62-adfa-17cc6fe71176","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|97c6f639-1406-4b5f-b3b0-6a41ad448756",
    path: "/product/set-afrika",
    meta: {"ID":"97c6f639-1406-4b5f-b3b0-6a41ad448756","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|99279ef9-49d5-49ba-9239-58820aeadbc3",
    path: "/product/zapechennaya-kalifornaya",
    meta: {"ID":"99279ef9-49d5-49ba-9239-58820aeadbc3","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|e0bf81c8-afe8-4300-a2d7-b02800d1792b|99b90c89-5724-4529-a6ec-7e1203a6234d",
    path: "/product/99b90c89-5724-4529-a6ec-7e1203a6234d",
    meta: {"ID":"99b90c89-5724-4529-a6ec-7e1203a6234d","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|99c5fa4e-37db-4734-baeb-dc876e28e7eb",
    path: "/product/roll-tekado",
    meta: {"ID":"99c5fa4e-37db-4734-baeb-dc876e28e7eb","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|9a58917b-c48e-4e56-a121-f583db8c17d5",
    path: "/product/pizza-rimskaya-zhulen",
    meta: {"ID":"9a58917b-c48e-4e56-a121-f583db8c17d5","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|9b21e3b3-1e8e-459b-a2ed-9fce9b0333ed",
    path: "/product/9b21e3b3-1e8e-459b-a2ed-9fce9b0333ed",
    meta: {"ID":"9b21e3b3-1e8e-459b-a2ed-9fce9b0333ed","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|9b7bc07a-7d48-4c48-8f9a-a5510fe68f27",
    path: "/product/9b7bc07a-7d48-4c48-8f9a-a5510fe68f27",
    meta: {"ID":"9b7bc07a-7d48-4c48-8f9a-a5510fe68f27","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|9b9fdf66-76f7-47ce-b0c5-59fd19992e7d",
    path: "/product/9b9fdf66-76f7-47ce-b0c5-59fd19992e7d",
    meta: {"ID":"9b9fdf66-76f7-47ce-b0c5-59fd19992e7d","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|9c4e61f1-7bc0-44d0-ad81-2f18b9735b4f",
    path: "/product/9c4e61f1-7bc0-44d0-ad81-2f18b9735b4f",
    meta: {"ID":"9c4e61f1-7bc0-44d0-ad81-2f18b9735b4f","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|9d3bad1f-666c-4033-8331-1cd1e3c74f1f",
    path: "/product/pizza-rimskaya-parmedzhano",
    meta: {"ID":"9d3bad1f-666c-4033-8331-1cd1e3c74f1f","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b4759fa8-c656-47bb-9006-af6600df98ee|9d7e2f96-a165-4a4b-a86e-dde96458d5df",
    path: "/product/9d7e2f96-a165-4a4b-a86e-dde96458d5df",
    meta: {"ID":"9d7e2f96-a165-4a4b-a86e-dde96458d5df","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|9e1e1b95-8acf-44f0-a7a8-10914d3ee949",
    path: "/product/9e1e1b95-8acf-44f0-a7a8-10914d3ee949",
    meta: {"ID":"9e1e1b95-8acf-44f0-a7a8-10914d3ee949","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|065cfcd4-7eab-4818-ad12-b0e000cccc3c|9f5ed1e0-4160-4a63-a94e-1339a88e6eea",
    path: "/product/krevetka-v-souse-guakamole-s-slivochnym-syrom-i-krasnoy-ikroy",
    meta: {"ID":"9f5ed1e0-4160-4a63-a94e-1339a88e6eea","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"065cfcd4-7eab-4818-ad12-b0e000cccc3c","GroupTitle":"Канапе","GroupLink":"/catalog/kanape","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7a725ed6-7233-47a5-a705-aeb400a2433d|9f7d7de4-7404-475a-8f6b-6f30a320c096",
    path: "/product/9f7d7de4-7404-475a-8f6b-6f30a320c096",
    meta: {"ID":"9f7d7de4-7404-475a-8f6b-6f30a320c096","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|9ff3152e-d417-4d3d-addc-19a6a06acf30",
    path: "/product/9ff3152e-d417-4d3d-addc-19a6a06acf30",
    meta: {"ID":"9ff3152e-d417-4d3d-addc-19a6a06acf30","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|a08b6d16-ae60-4c65-856a-954e9c0170c7",
    path: "/product/a08b6d16-ae60-4c65-856a-954e9c0170c7",
    meta: {"ID":"a08b6d16-ae60-4c65-856a-954e9c0170c7","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b2466b84-aea7-4496-8a4e-ae01009b292b|a0f2e28e-feaa-4eb2-835a-8e6b25443591",
    path: "/product/ovoschi-na-grile-200-g",
    meta: {"ID":"a0f2e28e-feaa-4eb2-835a-8e6b25443591","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bf7a754f-8fd7-4792-8ffa-af6600904e6a|a15e26da-06fd-4144-b276-3028692a5a63",
    path: "/product/midiya-losos",
    meta: {"ID":"a15e26da-06fd-4144-b276-3028692a5a63","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|a21bee7f-4acd-4992-a776-4e1b0654b94e",
    path: "/product/a21bee7f-4acd-4992-a776-4e1b0654b94e",
    meta: {"ID":"a21bee7f-4acd-4992-a776-4e1b0654b94e","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|a30d402d-3dc0-4d62-885c-9847a5a74cf7",
    path: "/product/pizza-4-syra",
    meta: {"ID":"a30d402d-3dc0-4d62-885c-9847a5a74cf7","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","AdditionalSeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b1a92c89-deb6-45a8-95d4-b12300a986e5|a40945f5-e20c-428e-9c9c-837d97125e9e",
    path: "/product/a40945f5-e20c-428e-9c9c-837d97125e9e",
    meta: {"ID":"a40945f5-e20c-428e-9c9c-837d97125e9e","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|a4abdfa1-70da-49a3-96d3-42faf9651120",
    path: "/product/pizza-klassika",
    meta: {"ID":"a4abdfa1-70da-49a3-96d3-42faf9651120","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|a4bae7a1-19d5-4338-95c4-220741349fa0",
    path: "/product/pizza-fermerskaya",
    meta: {"ID":"a4bae7a1-19d5-4338-95c4-220741349fa0","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|a4c52114-3473-4aad-9a67-ea7827ebaa22",
    path: "/product/a4c52114-3473-4aad-9a67-ea7827ebaa22",
    meta: {"ID":"a4c52114-3473-4aad-9a67-ea7827ebaa22","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7a725ed6-7233-47a5-a705-aeb400a2433d|a4cb16b4-a884-4814-b4e1-644104a508ce",
    path: "/product/a4cb16b4-a884-4814-b4e1-644104a508ce",
    meta: {"ID":"a4cb16b4-a884-4814-b4e1-644104a508ce","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|327b4329-2465-4bfc-b424-af660086214b|a5367e3e-26e3-4bb4-af8f-722c66537104",
    path: "/product/a5367e3e-26e3-4bb4-af8f-722c66537104",
    meta: {"ID":"a5367e3e-26e3-4bb4-af8f-722c66537104","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|a6ca21bb-0579-47bc-bfad-403298250866",
    path: "/product/a6ca21bb-0579-47bc-bfad-403298250866",
    meta: {"ID":"a6ca21bb-0579-47bc-bfad-403298250866","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|a70f55f1-6ed5-4349-a454-83557bbf4b39",
    path: "/product/a70f55f1-6ed5-4349-a454-83557bbf4b39",
    meta: {"ID":"a70f55f1-6ed5-4349-a454-83557bbf4b39","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|5c023689-385b-4718-8278-adfa00fa7f90|a79e7f0b-dec2-4c2a-8b7f-ec5dc94274cd",
    path: "/product/a79e7f0b-dec2-4c2a-8b7f-ec5dc94274cd",
    meta: {"ID":"a79e7f0b-dec2-4c2a-8b7f-ec5dc94274cd","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|a8ac561c-4aa9-4543-8337-33e5cca24795",
    path: "/product/skovoroda-kurinaya",
    meta: {"ID":"a8ac561c-4aa9-4543-8337-33e5cca24795","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|a8ba9603-94f4-4c1d-a800-0f446eed5950",
    path: "/product/a8ba9603-94f4-4c1d-a800-0f446eed5950",
    meta: {"ID":"a8ba9603-94f4-4c1d-a800-0f446eed5950","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|a90716c9-f239-4704-8a90-3fe4a0881207",
    path: "/product/pizza-sochnaya-govyadina",
    meta: {"ID":"a90716c9-f239-4704-8a90-3fe4a0881207","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|59107310-8498-4df1-b330-af6500eb304e|a9223cf3-e2ac-48bd-9b80-4265977a960c",
    path: "/product/a9223cf3-e2ac-48bd-9b80-4265977a960c",
    meta: {"ID":"a9223cf3-e2ac-48bd-9b80-4265977a960c","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bc37d11b-8dce-49ce-a28f-b12a00858730|aa3f7e92-1705-47b4-ab03-419227278a29",
    path: "/product/aa3f7e92-1705-47b4-ab03-419227278a29",
    meta: {"ID":"aa3f7e92-1705-47b4-ab03-419227278a29","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|5c023689-385b-4718-8278-adfa00fa7f90|aae6cbef-ff65-4f90-8569-001d0a534ad3",
    path: "/product/aae6cbef-ff65-4f90-8569-001d0a534ad3",
    meta: {"ID":"aae6cbef-ff65-4f90-8569-001d0a534ad3","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|ab4b93cb-40e7-4f1b-b3b7-497a9566d393",
    path: "/product/ab4b93cb-40e7-4f1b-b3b7-497a9566d393",
    meta: {"ID":"ab4b93cb-40e7-4f1b-b3b7-497a9566d393","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|ab87ec3e-0d66-4a39-ae84-ded89cbfba4c",
    path: "/product/pizza-parmedzhano",
    meta: {"ID":"ab87ec3e-0d66-4a39-ae84-ded89cbfba4c","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|abecd090-a917-4bf6-938e-0d9ec5df10a0",
    path: "/product/Kaliforniya-v-kunzhute-new",
    meta: {"ID":"abecd090-a917-4bf6-938e-0d9ec5df10a0","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b2c1dfa9-a3c8-4855-aa82-b02800d16650|abf48aae-85f1-416a-8370-cae166f9172a",
    path: "/product/nom-s-kuritsey-s",
    meta: {"ID":"abf48aae-85f1-416a-8370-cae166f9172a","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|327b4329-2465-4bfc-b424-af660086214b|acb414d7-2b73-4ec5-a722-448c0b139d1e",
    path: "/product/acb414d7-2b73-4ec5-a722-448c0b139d1e",
    meta: {"ID":"acb414d7-2b73-4ec5-a722-448c0b139d1e","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7fdabbb4-f2fe-4639-9608-ae2800938ea0|ae9a1061-cf82-47bd-8c43-fa1263c3ab06",
    path: "/product/burger-zavtrak-s-bekonom-b",
    meta: {"ID":"ae9a1061-cf82-47bd-8c43-fa1263c3ab06","Title":"","GroupID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","SubgroupID":null,"GroupTitle":"Бургеры","GroupLink":"/catalog/burgery","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|aed0a72d-22ea-4abd-9acc-db997ffa8ce4",
    path: "/product/aed0a72d-22ea-4abd-9acc-db997ffa8ce4",
    meta: {"ID":"aed0a72d-22ea-4abd-9acc-db997ffa8ce4","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea|af5ad032-787a-4324-8f79-bb10a3666ad4",
    path: "/product/tartaletki-krevetki-s-avokado-5-sht-po-20-gr",
    meta: {"ID":"af5ad032-787a-4324-8f79-bb10a3666ad4","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea","GroupTitle":"Тарталетки","GroupLink":"/catalog/tartaletki","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|065cfcd4-7eab-4818-ad12-b0e000cccc3c|afeeae45-45c7-49d0-a56b-ab458262198d",
    path: "/product/ikra-lososevaya-s-slivochnym-kremom",
    meta: {"ID":"afeeae45-45c7-49d0-a56b-ab458262198d","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"065cfcd4-7eab-4818-ad12-b0e000cccc3c","GroupTitle":"Канапе","GroupLink":"/catalog/kanape","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|b03a35ee-071d-42c7-ab73-dd1afcfe6c28",
    path: "/product/b03a35ee-071d-42c7-ab73-dd1afcfe6c28",
    meta: {"ID":"b03a35ee-071d-42c7-ab73-dd1afcfe6c28","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|b0632312-2f0f-49a3-b3bc-272af9dd63d6",
    path: "/product/b0632312-2f0f-49a3-b3bc-272af9dd63d6",
    meta: {"ID":"b0632312-2f0f-49a3-b3bc-272af9dd63d6","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b2c1dfa9-a3c8-4855-aa82-b02800d16650|b0832372-b7e9-4491-b7db-dd7c8f7bea41",
    path: "/product/olivye-b-p",
    meta: {"ID":"b0832372-b7e9-4491-b7db-dd7c8f7bea41","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|b14365cd-00d1-4019-9bab-57e288d18b29",
    path: "/product/b14365cd-00d1-4019-9bab-57e288d18b29",
    meta: {"ID":"b14365cd-00d1-4019-9bab-57e288d18b29","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|b14a1c29-242d-43ef-9023-1a45293e1d9f",
    path: "/product/b14a1c29-242d-43ef-9023-1a45293e1d9f",
    meta: {"ID":"b14a1c29-242d-43ef-9023-1a45293e1d9f","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea|b3c4953b-985f-4864-8833-150c4d2d62a7",
    path: "/product/tartaletki-s-slivochnym-syrom-i-krasnoy-ikroy",
    meta: {"ID":"b3c4953b-985f-4864-8833-150c4d2d62a7","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea","GroupTitle":"Тарталетки","GroupLink":"/catalog/tartaletki","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|b3ddd940-5e96-4f1b-880a-1a8abbe84b33",
    path: "/product/pizza-rimskaya-myasnoe-assorti",
    meta: {"ID":"b3ddd940-5e96-4f1b-880a-1a8abbe84b33","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|b4a5c4db-7476-43f7-a76c-af13e3eb03fc",
    path: "/product/set-fyuzhn-new",
    meta: {"ID":"b4a5c4db-7476-43f7-a76c-af13e3eb03fc","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b2c1dfa9-a3c8-4855-aa82-b02800d16650|b50bb3af-5d44-4aff-a8bc-4c3152879872",
    path: "/product/vinegret-s",
    meta: {"ID":"b50bb3af-5d44-4aff-a8bc-4c3152879872","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|b5799780-bd2d-4334-824f-ce2efb8a2a9e",
    path: "/product/pizza-s-moreproduktami",
    meta: {"ID":"b5799780-bd2d-4334-824f-ce2efb8a2a9e","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|1ccdeee5-60bf-4184-ba52-b0e000ccf59b|b6c9d505-b62c-4c7a-8c27-cffe7acd2691",
    path: "/product/kanape-selyodochka-s-kartofelem-i-luchkom",
    meta: {"ID":"b6c9d505-b62c-4c7a-8c27-cffe7acd2691","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupTitle":"Брускетты","GroupLink":"/catalog/bruskety","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|4bc7fa37-3143-486c-b7e5-f8e4cd99b330|b745c6fd-53d0-415d-bf57-20115a541897",
    path: "/product/b745c6fd-53d0-415d-bf57-20115a541897",
    meta: {"ID":"b745c6fd-53d0-415d-bf57-20115a541897","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|1ccdeee5-60bf-4184-ba52-b0e000ccf59b|b7671ab0-9dc1-4c18-98f4-f775174a544b",
    path: "/product/kanape-pepperoni-s-slivochnym-syrom",
    meta: {"ID":"b7671ab0-9dc1-4c18-98f4-f775174a544b","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupTitle":"Брускетты","GroupLink":"/catalog/bruskety","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|b81f0acb-43ab-4bb5-91ac-51ff470ddfb2",
    path: "/product/po-derevenski-pizza",
    meta: {"ID":"b81f0acb-43ab-4bb5-91ac-51ff470ddfb2","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|b85fc21f-587a-4ca6-816a-45fb47a0f715",
    path: "/product/chiken-spaysi-khot-polovinka",
    meta: {"ID":"b85fc21f-587a-4ca6-816a-45fb47a0f715","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b1a92c89-deb6-45a8-95d4-b12300a986e5|b95e6178-3676-47f0-a668-1bfb2b0b5846",
    path: "/product/b95e6178-3676-47f0-a668-1bfb2b0b5846",
    meta: {"ID":"b95e6178-3676-47f0-a668-1bfb2b0b5846","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|b9cb63b2-a66c-4ac7-849b-8148d12e8030",
    path: "/product/b9cb63b2-a66c-4ac7-849b-8148d12e8030",
    meta: {"ID":"b9cb63b2-a66c-4ac7-849b-8148d12e8030","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|353b1044-377a-4afe-8983-ae0600ca36e6|badb1cf4-337e-4d10-9b32-b65b5712b26c",
    path: "/product/badb1cf4-337e-4d10-9b32-b65b5712b26c",
    meta: {"ID":"badb1cf4-337e-4d10-9b32-b65b5712b26c","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|bb245ae0-f7fc-4dbc-abeb-e457fbf66b21",
    path: "/product/bb245ae0-f7fc-4dbc-abeb-e457fbf66b21",
    meta: {"ID":"bb245ae0-f7fc-4dbc-abeb-e457fbf66b21","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|bcf4339a-692f-4950-b86c-5eb0a5ad5d10",
    path: "/product/bcf4339a-692f-4950-b86c-5eb0a5ad5d10",
    meta: {"ID":"bcf4339a-692f-4950-b86c-5eb0a5ad5d10","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|bd20bced-c0d0-41af-873f-60dfddddf3e2",
    path: "/product/bd20bced-c0d0-41af-873f-60dfddddf3e2",
    meta: {"ID":"bd20bced-c0d0-41af-873f-60dfddddf3e2","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|bd94b3ab-8b00-44d2-bbe8-133a8028eab0",
    path: "/product/bd94b3ab-8b00-44d2-bbe8-133a8028eab0",
    meta: {"ID":"bd94b3ab-8b00-44d2-bbe8-133a8028eab0","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b1a92c89-deb6-45a8-95d4-b12300a986e5|be10d620-078b-484b-8646-2f2e329cb4a2",
    path: "/product/be10d620-078b-484b-8646-2f2e329cb4a2",
    meta: {"ID":"be10d620-078b-484b-8646-2f2e329cb4a2","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|be4d0f9f-1b78-4dd0-bb93-b3763ca4b953",
    path: "/product/be4d0f9f-1b78-4dd0-bb93-b3763ca4b953",
    meta: {"ID":"be4d0f9f-1b78-4dd0-bb93-b3763ca4b953","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|065cfcd4-7eab-4818-ad12-b0e000cccc3c|bea87b72-8748-41c3-beb0-96540447ca5e",
    path: "/product/kanape-na-krekere-s-kolbaskami-perepelinym-yaytsom-i-ogurchikom",
    meta: {"ID":"bea87b72-8748-41c3-beb0-96540447ca5e","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"065cfcd4-7eab-4818-ad12-b0e000cccc3c","GroupTitle":"Канапе","GroupLink":"/catalog/kanape","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|bf351b0d-105f-4965-8be2-b83fa2b09eea",
    path: "/product/bf351b0d-105f-4965-8be2-b83fa2b09eea",
    meta: {"ID":"bf351b0d-105f-4965-8be2-b83fa2b09eea","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b2c1dfa9-a3c8-4855-aa82-b02800d16650|bffaa49b-a1aa-4114-8c26-eefdbffd62d7",
    path: "/product/bffaa49b-a1aa-4114-8c26-eefdbffd62d7",
    meta: {"ID":"bffaa49b-a1aa-4114-8c26-eefdbffd62d7","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|c062ec68-e441-41fe-a5b5-d4de19054a98",
    path: "/product/c062ec68-e441-41fe-a5b5-d4de19054a98",
    meta: {"ID":"c062ec68-e441-41fe-a5b5-d4de19054a98","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|c122734c-6c0a-4e0d-b345-2b128c4fe6a1",
    path: "/product/c122734c-6c0a-4e0d-b345-2b128c4fe6a1",
    meta: {"ID":"c122734c-6c0a-4e0d-b345-2b128c4fe6a1","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|c13850b8-3447-46e4-a5ea-d19bc7d11334",
    path: "/product/c13850b8-3447-46e4-a5ea-d19bc7d11334",
    meta: {"ID":"c13850b8-3447-46e4-a5ea-d19bc7d11334","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|c2019e54-9af3-47cc-9f5f-35e17f9eb81d",
    path: "/product/c2019e54-9af3-47cc-9f5f-35e17f9eb81d",
    meta: {"ID":"c2019e54-9af3-47cc-9f5f-35e17f9eb81d","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bc37d11b-8dce-49ce-a28f-b12a00858730|c21a08ac-e882-4a68-9c31-38845ea36df9",
    path: "/product/c21a08ac-e882-4a68-9c31-38845ea36df9",
    meta: {"ID":"c21a08ac-e882-4a68-9c31-38845ea36df9","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|c24bf643-c58c-469d-8da2-171d62b5b362",
    path: "/product/set-fishka",
    meta: {"ID":"c24bf643-c58c-469d-8da2-171d62b5b362","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|c2711da9-cf2d-4d6e-be47-112fd46e6a76",
    path: "/product/c2711da9-cf2d-4d6e-be47-112fd46e6a76",
    meta: {"ID":"c2711da9-cf2d-4d6e-be47-112fd46e6a76","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|c2b7aa04-ac3e-4344-9649-5d2ee6cc5559",
    path: "/product/roll-kaliforniya-s-ugryom",
    meta: {"ID":"c2b7aa04-ac3e-4344-9649-5d2ee6cc5559","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7fdabbb4-f2fe-4639-9608-ae2800938ea0|c2e35fb3-5959-4e94-ae2e-0ce287c437bd",
    path: "/product/burger-chiz",
    meta: {"ID":"c2e35fb3-5959-4e94-ae2e-0ce287c437bd","Title":"","GroupID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","SubgroupID":null,"GroupTitle":"Бургеры","GroupLink":"/catalog/burgery","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|998d3729-dd15-45c9-aa7d-b0c10086fc3e|c45eac8c-dbd5-4aba-b385-8751559d5f6d",
    path: "/product/c45eac8c-dbd5-4aba-b385-8751559d5f6d",
    meta: {"ID":"c45eac8c-dbd5-4aba-b385-8751559d5f6d","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|c57819ee-7bca-4165-9483-98994ff8298c",
    path: "/product/c57819ee-7bca-4165-9483-98994ff8298c",
    meta: {"ID":"c57819ee-7bca-4165-9483-98994ff8298c","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|c5bc431f-2b8c-451f-b101-eaa139b84e68",
    path: "/product/pizza-rimskaya-karbonara",
    meta: {"ID":"c5bc431f-2b8c-451f-b101-eaa139b84e68","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b4759fa8-c656-47bb-9006-af6600df98ee|c719a2b1-cac4-4b39-bac1-2c7ef4dc5c98",
    path: "/product/c719a2b1-cac4-4b39-bac1-2c7ef4dc5c98",
    meta: {"ID":"c719a2b1-cac4-4b39-bac1-2c7ef4dc5c98","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|59107310-8498-4df1-b330-af6500eb304e|c8759615-d908-4c31-a8de-c00127813f27",
    path: "/product/c8759615-d908-4c31-a8de-c00127813f27",
    meta: {"ID":"c8759615-d908-4c31-a8de-c00127813f27","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|8899d7d0-89e7-4532-99ef-b02800d16bfd|c8d94c01-34a6-488e-8e6d-b06695d917a7",
    path: "/product/c8d94c01-34a6-488e-8e6d-b06695d917a7",
    meta: {"ID":"c8d94c01-34a6-488e-8e6d-b06695d917a7","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|ce15c14e-c2dc-4490-a524-a1750b1d0338",
    path: "/product/ce15c14e-c2dc-4490-a524-a1750b1d0338",
    meta: {"ID":"ce15c14e-c2dc-4490-a524-a1750b1d0338","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7fdabbb4-f2fe-4639-9608-ae2800938ea0|ce8cf155-79ce-4229-99ce-6a25400ace16",
    path: "/product/burger-s-yaytsom",
    meta: {"ID":"ce8cf155-79ce-4229-99ce-6a25400ace16","Title":"","GroupID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","SubgroupID":null,"GroupTitle":"Бургеры","GroupLink":"/catalog/burgery","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|77c22e69-2462-4bd5-9240-b0e000cca68b|cecb2ba6-2399-4210-9238-bc20edc8d371",
    path: "/product/salat-grecheskiy-v-ryumke",
    meta: {"ID":"cecb2ba6-2399-4210-9238-bc20edc8d371","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"77c22e69-2462-4bd5-9240-b0e000cca68b","GroupTitle":"Салат","GroupLink":"/catalog/salat","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|cf0b5810-5542-41d6-9869-b1b050a4bb31",
    path: "/product/cf0b5810-5542-41d6-9869-b1b050a4bb31",
    meta: {"ID":"cf0b5810-5542-41d6-9869-b1b050a4bb31","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea|cfbc5e31-416f-4e97-99f0-56e3bc635416",
    path: "/product/tartaletki-s-lososem-slivochnym-syrom-i-krasnoy-ikroy",
    meta: {"ID":"cfbc5e31-416f-4e97-99f0-56e3bc635416","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea","GroupTitle":"Тарталетки","GroupLink":"/catalog/tartaletki","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b1a92c89-deb6-45a8-95d4-b12300a986e5|d03410f7-18e1-4d22-abb1-de18d9a2a743",
    path: "/product/d03410f7-18e1-4d22-abb1-de18d9a2a743",
    meta: {"ID":"d03410f7-18e1-4d22-abb1-de18d9a2a743","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|d034dfcc-5de5-4893-8acc-72e4e84566b0",
    path: "/product/d034dfcc-5de5-4893-8acc-72e4e84566b0",
    meta: {"ID":"d034dfcc-5de5-4893-8acc-72e4e84566b0","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|d0811108-ab23-4979-aa13-91dcb63d358c",
    path: "/product/d0811108-ab23-4979-aa13-91dcb63d358c",
    meta: {"ID":"d0811108-ab23-4979-aa13-91dcb63d358c","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bc37d11b-8dce-49ce-a28f-b12a00858730|d0e2a846-dcbb-4655-b4fc-2af4e4001210",
    path: "/product/d0e2a846-dcbb-4655-b4fc-2af4e4001210",
    meta: {"ID":"d0e2a846-dcbb-4655-b4fc-2af4e4001210","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|1ccdeee5-60bf-4184-ba52-b0e000ccf59b|d136b185-22e6-443e-8350-2c70275b3cdb",
    path: "/product/mini-brusketty-salyami-s-slivochnym-syrom",
    meta: {"ID":"d136b185-22e6-443e-8350-2c70275b3cdb","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupTitle":"Брускетты","GroupLink":"/catalog/bruskety","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|353b1044-377a-4afe-8983-ae0600ca36e6|d18a1d78-d217-451b-8515-bdd7f3fbc7d0",
    path: "/product/d18a1d78-d217-451b-8515-bdd7f3fbc7d0",
    meta: {"ID":"d18a1d78-d217-451b-8515-bdd7f3fbc7d0","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|1ccdeee5-60bf-4184-ba52-b0e000ccf59b|d27177e0-4f8a-4863-a757-bb6a3a41c0c1",
    path: "/product/khrustyaschaya-chiabatta-s-salyami-i-maslinami",
    meta: {"ID":"d27177e0-4f8a-4863-a757-bb6a3a41c0c1","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupTitle":"Брускетты","GroupLink":"/catalog/bruskety","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|327b4329-2465-4bfc-b424-af660086214b|d4ca2020-bee0-4f39-b5e4-6429811f88af",
    path: "/product/d4ca2020-bee0-4f39-b5e4-6429811f88af",
    meta: {"ID":"d4ca2020-bee0-4f39-b5e4-6429811f88af","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|d4e12855-11c5-4389-be87-f470c5e0abf7",
    path: "/product/d4e12855-11c5-4389-be87-f470c5e0abf7",
    meta: {"ID":"d4e12855-11c5-4389-be87-f470c5e0abf7","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|d546c910-661b-4e46-affa-f0839969f1fa",
    path: "/product/d546c910-661b-4e46-affa-f0839969f1fa",
    meta: {"ID":"d546c910-661b-4e46-affa-f0839969f1fa","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|1ccdeee5-60bf-4184-ba52-b0e000ccf59b|d58deef4-9eb6-4d49-8eae-2acb05c0ea3e",
    path: "/product/mini-brusketty-s-lososem-ogurtsom-i-slivochnym-syrom",
    meta: {"ID":"d58deef4-9eb6-4d49-8eae-2acb05c0ea3e","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupTitle":"Брускетты","GroupLink":"/catalog/bruskety","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|766563a0-82d3-47ce-8d74-aeda00eaca0e|d5b7725b-82fb-4fb7-9d87-7b04d32f5d15",
    path: "/product/d5b7725b-82fb-4fb7-9d87-7b04d32f5d15",
    meta: {"ID":"d5b7725b-82fb-4fb7-9d87-7b04d32f5d15","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7fdabbb4-f2fe-4639-9608-ae2800938ea0|d606c96b-f1a6-4d90-90c6-cce0bb25dc2f",
    path: "/product/big-burger",
    meta: {"ID":"d606c96b-f1a6-4d90-90c6-cce0bb25dc2f","Title":"","GroupID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","SubgroupID":null,"GroupTitle":"Бургеры","GroupLink":"/catalog/burgery","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|d712bd09-b289-43e2-8dca-a2c596933741",
    path: "/product/roll-kioto",
    meta: {"ID":"d712bd09-b289-43e2-8dca-a2c596933741","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|f0ef77f3-654b-4e89-ac5b-af660095e2d3|d7225f84-0e1c-4b36-8e46-3703b8ac0d53",
    path: "/product/d7225f84-0e1c-4b36-8e46-3703b8ac0d53",
    meta: {"ID":"d7225f84-0e1c-4b36-8e46-3703b8ac0d53","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|8899d7d0-89e7-4532-99ef-b02800d16bfd|d7845217-384d-453c-ad2b-d05ef8cd481b",
    path: "/product/olive",
    meta: {"ID":"d7845217-384d-453c-ad2b-d05ef8cd481b","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|d899c982-2bad-4167-8648-8d1ec3a31392",
    path: "/product/set-lososeviy-khit",
    meta: {"ID":"d899c982-2bad-4167-8648-8d1ec3a31392","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|d8d045cf-4fbc-4bb6-a504-a5fd3ffd83b5",
    path: "/product/midiya-losos-polovinka",
    meta: {"ID":"d8d045cf-4fbc-4bb6-a504-a5fd3ffd83b5","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|d90add90-c341-41d9-b286-66003ae43ecf",
    path: "/product/d90add90-c341-41d9-b286-66003ae43ecf",
    meta: {"ID":"d90add90-c341-41d9-b286-66003ae43ecf","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|dc00e481-4b3f-4779-a18d-995640e2b81d",
    path: "/product/roll-darvin",
    meta: {"ID":"dc00e481-4b3f-4779-a18d-995640e2b81d","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|77c22e69-2462-4bd5-9240-b0e000cca68b|dc2879ea-8a1b-4548-b0d4-03cdd3e3061a",
    path: "/product/salat-tsezar-s-kuritsey-1-kg",
    meta: {"ID":"dc2879ea-8a1b-4548-b0d4-03cdd3e3061a","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"77c22e69-2462-4bd5-9240-b0e000cca68b","GroupTitle":"Салат","GroupLink":"/catalog/salat","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7fdabbb4-f2fe-4639-9608-ae2800938ea0|dc9ebe58-66e6-41d0-9be4-c795e3283285",
    path: "/product/gamburger",
    meta: {"ID":"dc9ebe58-66e6-41d0-9be4-c795e3283285","Title":"","GroupID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","SubgroupID":null,"GroupTitle":"Бургеры","GroupLink":"/catalog/burgery","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|f0ef77f3-654b-4e89-ac5b-af660095e2d3|dccd2c74-2d25-4a65-98bf-3a348aa6a2e8",
    path: "/product/dccd2c74-2d25-4a65-98bf-3a348aa6a2e8",
    meta: {"ID":"dccd2c74-2d25-4a65-98bf-3a348aa6a2e8","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|77c22e69-2462-4bd5-9240-b0e000cca68b|df426afc-4b4d-4f1e-b66e-03e3974ab7d0",
    path: "/product/salat-olive-v-ryumke",
    meta: {"ID":"df426afc-4b4d-4f1e-b66e-03e3974ab7d0","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"77c22e69-2462-4bd5-9240-b0e000cca68b","GroupTitle":"Салат","GroupLink":"/catalog/salat","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|998d3729-dd15-45c9-aa7d-b0c10086fc3e|df4d156a-4b06-4a5f-b282-c1a05679010a",
    path: "/product/df4d156a-4b06-4a5f-b282-c1a05679010a",
    meta: {"ID":"df4d156a-4b06-4a5f-b282-c1a05679010a","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|065cfcd4-7eab-4818-ad12-b0e000cccc3c|df53bdd7-507d-4d90-bb05-c6a3cd8876c7",
    path: "/product/kanape-fruktovoe",
    meta: {"ID":"df53bdd7-507d-4d90-bb05-c6a3cd8876c7","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"065cfcd4-7eab-4818-ad12-b0e000cccc3c","GroupTitle":"Канапе","GroupLink":"/catalog/kanape","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|e059cc27-e189-44e0-925a-589ffa46a4b5",
    path: "/product/e059cc27-e189-44e0-925a-589ffa46a4b5",
    meta: {"ID":"e059cc27-e189-44e0-925a-589ffa46a4b5","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|bf7a754f-8fd7-4792-8ffa-af6600904e6a|e08dbc4b-3342-4058-8f49-06dd466df087",
    path: "/product/sochniy-losos",
    meta: {"ID":"e08dbc4b-3342-4058-8f49-06dd466df087","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|e11ee4e3-41e0-44da-b924-332458cb6798",
    path: "/product/e11ee4e3-41e0-44da-b924-332458cb6798",
    meta: {"ID":"e11ee4e3-41e0-44da-b924-332458cb6798","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|e1920e39-b365-4129-9217-8764c9ddd720",
    path: "/product/skovoroda-so-svininoy",
    meta: {"ID":"e1920e39-b365-4129-9217-8764c9ddd720","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|e1c716a6-1f4d-473a-bcde-89651954861a",
    path: "/product/e1c716a6-1f4d-473a-bcde-89651954861a",
    meta: {"ID":"e1c716a6-1f4d-473a-bcde-89651954861a","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|8899d7d0-89e7-4532-99ef-b02800d16bfd|e281e097-3446-4093-b1f5-5c33c4c98bab",
    path: "/product/e281e097-3446-4093-b1f5-5c33c4c98bab",
    meta: {"ID":"e281e097-3446-4093-b1f5-5c33c4c98bab","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|e28451c1-fe38-43e2-8286-9fe9e14cde5c",
    path: "/product/e28451c1-fe38-43e2-8286-9fe9e14cde5c",
    meta: {"ID":"e28451c1-fe38-43e2-8286-9fe9e14cde5c","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|e3f24ae5-da4d-4c8d-8244-ce0699f9c30e",
    path: "/product/e3f24ae5-da4d-4c8d-8244-ce0699f9c30e",
    meta: {"ID":"e3f24ae5-da4d-4c8d-8244-ce0699f9c30e","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|e463c58e-1712-4d98-8a82-f75b626a16d8",
    path: "/product/e463c58e-1712-4d98-8a82-f75b626a16d8",
    meta: {"ID":"e463c58e-1712-4d98-8a82-f75b626a16d8","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|85a5e411-a9e8-497d-b0d4-aeda00eac882|e497e56c-2d9b-47f5-8ef8-5c3e01e637aa",
    path: "/product/e497e56c-2d9b-47f5-8ef8-5c3e01e637aa",
    meta: {"ID":"e497e56c-2d9b-47f5-8ef8-5c3e01e637aa","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|e6b92769-34c0-4cce-8373-fcdee00a0f2d",
    path: "/product/befstroganov",
    meta: {"ID":"e6b92769-34c0-4cce-8373-fcdee00a0f2d","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b1a92c89-deb6-45a8-95d4-b12300a986e5|e6de17a9-ca21-47b5-a63a-f8eb3029ad72",
    path: "/product/e6de17a9-ca21-47b5-a63a-f8eb3029ad72",
    meta: {"ID":"e6de17a9-ca21-47b5-a63a-f8eb3029ad72","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|e8f95e81-ffb6-4b4d-9864-69d26ccbafc8",
    path: "/product/e8f95e81-ffb6-4b4d-9864-69d26ccbafc8",
    meta: {"ID":"e8f95e81-ffb6-4b4d-9864-69d26ccbafc8","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|998d3729-dd15-45c9-aa7d-b0c10086fc3e|e9bc474d-17de-4e44-b2ec-36ec966f8be7",
    path: "/product/e9bc474d-17de-4e44-b2ec-36ec966f8be7",
    meta: {"ID":"e9bc474d-17de-4e44-b2ec-36ec966f8be7","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2ba54d74-f42e-4df4-8d69-b0e600cba595|e9ce9254-cfe2-4034-9c6f-66af1fff77bc",
    path: "/product/ovoschi-na-grile-1-kg",
    meta: {"ID":"e9ce9254-cfe2-4034-9c6f-66af1fff77bc","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"2ba54d74-f42e-4df4-8d69-b0e600cba595","GroupTitle":"Горячие закуски","GroupLink":"/catalog/goryachie-zakuski","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|e9fb4e7d-ceab-417b-b381-7dea48d26e58",
    path: "/product/e9fb4e7d-ceab-417b-b381-7dea48d26e58",
    meta: {"ID":"e9fb4e7d-ceab-417b-b381-7dea48d26e58","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|59107310-8498-4df1-b330-af6500eb304e|e9fccdd9-3cec-44a3-8cf8-e11b2f90373f",
    path: "/product/e9fccdd9-3cec-44a3-8cf8-e11b2f90373f",
    meta: {"ID":"e9fccdd9-3cec-44a3-8cf8-e11b2f90373f","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|ea27e2e9-835e-4d2c-988d-f281190505e9",
    path: "/product/syrniy-tsyplenok-pizza",
    meta: {"ID":"ea27e2e9-835e-4d2c-988d-f281190505e9","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|eaf6d9f2-4685-4c3c-8e7e-9e9653f4203b",
    path: "/product/kolbaski-govyazhi-gril-s-ovoschami",
    meta: {"ID":"eaf6d9f2-4685-4c3c-8e7e-9e9653f4203b","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|eb8a8b0e-a58d-4ef2-b9da-307f083f3e42",
    path: "/product/sochniy-losos-polovinka",
    meta: {"ID":"eb8a8b0e-a58d-4ef2-b9da-307f083f3e42","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|ec261c29-1f07-448b-953b-6872ce0e8f7a",
    path: "/product/pizza-chili",
    meta: {"ID":"ec261c29-1f07-448b-953b-6872ce0e8f7a","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea|ec718ce8-ff65-42ec-ace3-7ae0f21fee79",
    path: "/product/tartaletki-pepperoni-s-ananasom",
    meta: {"ID":"ec718ce8-ff65-42ec-ace3-7ae0f21fee79","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"dc6dbb41-4a0d-4ac3-9440-b0e000ccc4ea","GroupTitle":"Тарталетки","GroupLink":"/catalog/tartaletki","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|ec92f4bc-28df-4068-8092-fa4ac97e6565",
    path: "/product/ec92f4bc-28df-4068-8092-fa4ac97e6565",
    meta: {"ID":"ec92f4bc-28df-4068-8092-fa4ac97e6565","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|ecaa9cf6-3cbd-41f6-9a28-e07aee82b8a6",
    path: "/product/ecaa9cf6-3cbd-41f6-9a28-e07aee82b8a6",
    meta: {"ID":"ecaa9cf6-3cbd-41f6-9a28-e07aee82b8a6","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|ed61cd28-4ccf-4485-8cc0-12ed8198c836",
    path: "/product/ed61cd28-4ccf-4485-8cc0-12ed8198c836",
    meta: {"ID":"ed61cd28-4ccf-4485-8cc0-12ed8198c836","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|ee0c3a88-5196-440a-aa42-491b3fb55958",
    path: "/product/ee0c3a88-5196-440a-aa42-491b3fb55958",
    meta: {"ID":"ee0c3a88-5196-440a-aa42-491b3fb55958","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|ee4d1c89-952c-4817-8c44-5e35902b6647",
    path: "/product/pizza-rimskaya-s-tsyplenkom-i-syrom-dor-blyu",
    meta: {"ID":"ee4d1c89-952c-4817-8c44-5e35902b6647","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|eeb3b635-b863-46ac-ae29-5dee57bb1195",
    path: "/product/eeb3b635-b863-46ac-ae29-5dee57bb1195",
    meta: {"ID":"eeb3b635-b863-46ac-ae29-5dee57bb1195","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|ef5d6b48-4cf3-4295-9a7b-3ac43ba2e950",
    path: "/product/ef5d6b48-4cf3-4295-9a7b-3ac43ba2e950",
    meta: {"ID":"ef5d6b48-4cf3-4295-9a7b-3ac43ba2e950","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|766563a0-82d3-47ce-8d74-aeda00eaca0e|efd169e0-d3e5-4bce-be07-32802f5af198",
    path: "/product/efd169e0-d3e5-4bce-be07-32802f5af198",
    meta: {"ID":"efd169e0-d3e5-4bce-be07-32802f5af198","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|f0040918-b92d-43b7-ad7b-c745dbb596b3",
    path: "/product/pepperoni-pizza",
    meta: {"ID":"f0040918-b92d-43b7-ad7b-c745dbb596b3","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|353b1044-377a-4afe-8983-ae0600ca36e6|f02ef0f5-22ba-4840-b59f-857f69927fbf",
    path: "/product/f02ef0f5-22ba-4840-b59f-857f69927fbf",
    meta: {"ID":"f02ef0f5-22ba-4840-b59f-857f69927fbf","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|1ccdeee5-60bf-4184-ba52-b0e000ccf59b|f0e72f5c-7aba-4fe1-bcef-dd0fce49cce3",
    path: "/product/khrustyaschaya-chiabatta-s-krevetkami-pod-sousom-guakamole",
    meta: {"ID":"f0e72f5c-7aba-4fe1-bcef-dd0fce49cce3","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"1ccdeee5-60bf-4184-ba52-b0e000ccf59b","GroupTitle":"Брускетты","GroupLink":"/catalog/bruskety","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|460553a8-db69-4af8-b848-ae1b007a3010|f216c83e-6c60-4bcf-be96-80bc57830a78",
    path: "/product/f216c83e-6c60-4bcf-be96-80bc57830a78",
    meta: {"ID":"f216c83e-6c60-4bcf-be96-80bc57830a78","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","AdditionalSeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|f224c477-d531-49c5-be76-c5553eb39511",
    path: "/product/f224c477-d531-49c5-be76-c5553eb39511",
    meta: {"ID":"f224c477-d531-49c5-be76-c5553eb39511","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|f33b8ae0-f71d-40b5-aff4-d734788dfc15",
    path: "/product/roll-kaliforniya-s-lososem",
    meta: {"ID":"f33b8ae0-f71d-40b5-aff4-d734788dfc15","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|77c22e69-2462-4bd5-9240-b0e000cca68b|f3edb10a-a18d-4c4e-912e-ffd5d3043c52",
    path: "/product/salat-seled-pod-shuboy-v-ryumke",
    meta: {"ID":"f3edb10a-a18d-4c4e-912e-ffd5d3043c52","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"77c22e69-2462-4bd5-9240-b0e000cca68b","GroupTitle":"Салат","GroupLink":"/catalog/salat","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|0bca87e9-ad26-46d4-8ad2-ae3200a06f45|f47bdf02-fc0e-4570-b33f-91a2719ca220",
    path: "/product/f47bdf02-fc0e-4570-b33f-91a2719ca220",
    meta: {"ID":"f47bdf02-fc0e-4570-b33f-91a2719ca220","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|998d3729-dd15-45c9-aa7d-b0c10086fc3e|f4e54c5a-327d-4868-8b26-0946b9e10b71",
    path: "/product/f4e54c5a-327d-4868-8b26-0946b9e10b71",
    meta: {"ID":"f4e54c5a-327d-4868-8b26-0946b9e10b71","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|065cfcd4-7eab-4818-ad12-b0e000cccc3c|f4f7602c-896c-4fb9-adaf-ee2a532b3716",
    path: "/product/kanape-po-grecheski",
    meta: {"ID":"f4f7602c-896c-4fb9-adaf-ee2a532b3716","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"065cfcd4-7eab-4818-ad12-b0e000cccc3c","GroupTitle":"Канапе","GroupLink":"/catalog/kanape","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|f51b9372-3bfb-474e-9581-22498ebff374",
    path: "/product/f51b9372-3bfb-474e-9581-22498ebff374",
    meta: {"ID":"f51b9372-3bfb-474e-9581-22498ebff374","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|6e7b5518-1053-459c-b5a3-af4100e646fd|f5601659-0cd0-41a2-ba4a-56d713045d3d",
    path: "/product/f5601659-0cd0-41a2-ba4a-56d713045d3d",
    meta: {"ID":"f5601659-0cd0-41a2-ba4a-56d713045d3d","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|f588775d-d73d-4276-9999-18cf246e8f56",
    path: "/product/f588775d-d73d-4276-9999-18cf246e8f56",
    meta: {"ID":"f588775d-d73d-4276-9999-18cf246e8f56","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|a8591e90-1085-4f8c-828f-ade900cea7ff|f63f7ec9-d1b7-4cf7-aa47-dd5f14e7d5e3",
    path: "/product/f63f7ec9-d1b7-4cf7-aa47-dd5f14e7d5e3",
    meta: {"ID":"f63f7ec9-d1b7-4cf7-aa47-dd5f14e7d5e3","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|766563a0-82d3-47ce-8d74-aeda00eaca0e|f72d5897-7523-44a2-ad83-65a83e314e4d",
    path: "/product/f72d5897-7523-44a2-ad83-65a83e314e4d",
    meta: {"ID":"f72d5897-7523-44a2-ad83-65a83e314e4d","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|b2c1dfa9-a3c8-4855-aa82-b02800d16650|f73b28e8-e78a-4f92-a88a-fa8ff9c05074",
    path: "/product/f73b28e8-e78a-4f92-a88a-fa8ff9c05074",
    meta: {"ID":"f73b28e8-e78a-4f92-a88a-fa8ff9c05074","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|88cd3140-5d31-4223-965f-adea00647536|f7a4d0f7-05b8-40e6-a413-455620fab152",
    path: "/product/margarita-pizza",
    meta: {"ID":"f7a4d0f7-05b8-40e6-a413-455620fab152","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"88cd3140-5d31-4223-965f-adea00647536","GroupTitle":"Классическая пицца","GroupLink":"/catalog/klassicheskaya-pizza","SeoText":"","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|e0bf81c8-afe8-4300-a2d7-b02800d1792b|f95b94d2-6626-47c5-bf37-678ecd18da58",
    path: "/product/f95b94d2-6626-47c5-bf37-678ecd18da58",
    meta: {"ID":"f95b94d2-6626-47c5-bf37-678ecd18da58","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|f961721e-f5b7-4660-8721-5a264f8ec854",
    path: "/product/goryachiy-tsezar",
    meta: {"ID":"f961721e-f5b7-4660-8721-5a264f8ec854","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|19db749b-f0cc-4fa5-8162-b08100cb3c6c|f9834db4-dfdd-4a3e-8849-8d337af556ca",
    path: "/product/pizza-rimskaya-fermerskaya",
    meta: {"ID":"f9834db4-dfdd-4a3e-8849-8d337af556ca","Title":"","GroupID":"39537111-1e18-4bac-9454-b02a009d3d37","SubgroupID":"19db749b-f0cc-4fa5-8162-b08100cb3c6c","GroupTitle":"Римская пицца","GroupLink":"/catalog/rimskaya-pizza","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|3e1143c7-38d5-4039-a7a1-aeda00ebb794|fb331ecc-ee8d-4113-ac77-c5c066fe4f24",
    path: "/product/kranch-s-lososem",
    meta: {"ID":"fb331ecc-ee8d-4113-ac77-c5c066fe4f24","Title":"","SubgroupID":null,"GroupLink":"","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2ba54d74-f42e-4df4-8d69-b0e600cba595|fba64195-a6b1-476a-9dca-7b6e8b047e6b",
    path: "/product/shashlychki-kurinye-v-souse-svit-chili-1-kg",
    meta: {"ID":"fba64195-a6b1-476a-9dca-7b6e8b047e6b","Title":"","GroupID":"d51fb741-dc07-4264-84ea-b0e000cd8fa2","SubgroupID":"2ba54d74-f42e-4df4-8d69-b0e600cba595","GroupTitle":"Горячие закуски","GroupLink":"/catalog/goryachie-zakuski","MetaTags":[],"UseAlternateTheme":false},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|fca82df2-34dc-41d1-a32e-28653c95024e",
    path: "/product/fca82df2-34dc-41d1-a32e-28653c95024e",
    meta: {"ID":"fca82df2-34dc-41d1-a32e-28653c95024e","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|2a11bb3c-2d0f-4417-9ff0-ae3200a05b8b|fccb011b-28f0-48b9-be02-3b8ba92f988c",
    path: "/product/fccb011b-28f0-48b9-be02-3b8ba92f988c",
    meta: {"ID":"fccb011b-28f0-48b9-be02-3b8ba92f988c","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|7fdabbb4-f2fe-4639-9608-ae2800938ea0|fd4390db-db6c-46d2-8f1d-a6de084cb11f",
    path: "/product/burger-s-kuritsey",
    meta: {"ID":"fd4390db-db6c-46d2-8f1d-a6de084cb11f","Title":"","GroupID":"7fdabbb4-f2fe-4639-9608-ae2800938ea0","SubgroupID":null,"GroupTitle":"Бургеры","GroupLink":"/catalog/burgery","SeoText":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|423e53e4-0596-4c44-866d-aeda00eacc8f|fe48bc1a-fd22-41e8-a80a-f7f2ea9ad5d0",
    path: "/product/fe48bc1a-fd22-41e8-a80a-f7f2ea9ad5d0",
    meta: {"ID":"fe48bc1a-fd22-41e8-a80a-f7f2ea9ad5d0","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "product|48adb89e-9ec0-4793-9712-ae3200a04ed4|ff43346c-e753-4993-bf8d-a8c34b84d5fe",
    path: "/product/ff43346c-e753-4993-bf8d-a8c34b84d5fe",
    meta: {"ID":"ff43346c-e753-4993-bf8d-a8c34b84d5fe","Title":"","SubgroupID":null,"GroupLink":"","MetaTags":[]},
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/menu/product/[id].vue")
  },
  {
    name: "legal-pay-rules",
    path: "/rules",
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/legal.vue")
  },
  {
    name: "legal-personal-data",
    path: "/personaldata",
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/legal.vue")
  },
  {
    name: "legal-agreement",
    path: "/privacy",
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/legal.vue")
  },
  {
    name: "legal-points",
    path: "/points",
    component: () => import("D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/pages/legal.vue")
  }
]