
import * as aroraProviderRuntime$bkwLIlFilI from 'D:/deploy/UploadHidden/20241127-T903.354/arora-front/satellite/build-rest-8ba3978f-4503-4a99-81d2-102d3f1e10ca/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$bkwLIlFilI, defaults: {} }
}
        