<i18n>
ru:
  showText: Показать текст
  hideText: Скрыть текст

ua:
  showText: Показати текст
  hideText: Приховати текст

us:
  showText: Show text
  hideText: Hide text
</i18n>

<template>
  <ClientOnly>
    <template #fallback>
      <div class="v-container">
        <div v-html="text" />
      </div>
    </template>
    <div class="v-container">
      <div class="v-row">
        <div class="v-col-12">
          <div
            v-if="
              isMainPage &&
              (imageExists(appConfig.VueSettingsPreRun.MainPageSEOBannerDesktop) ||
                imageExists(appConfig.VueSettingsPreRun.MainPageSEOBannerTablet) ||
                imageExists(appConfig.VueSettingsPreRun.MainPageSEOBannerMobile))
            "
            class="v-seo-banner__block"
          >
            <div class="v-text-absolute">
              <div class="v-summary-text v-d-flex v-flex-column v-text-center">
                <div
                  class="v-title"
                  v-html="sanitize(appConfig.VueSettingsPreRun.MainPageSEOBannerTitle)"
                />
                <div
                  class="v-description"
                  v-html="sanitize(appConfig.VueSettingsPreRun.MainPageSEOBannerDescription)"
                />
                <arora-nuxt-link
                  class-name="v-btn v-btn-primary v-seo-banner-link"
                  :href="appConfig.VueSettingsPreRun.MainPageSEOBannerButtonLink"
                  :label="sanitize(appConfig.VueSettingsPreRun.MainPageSEOBannerButton)"
                />
              </div>
            </div>
            <div class="v-img-relative">
              <arora-nuxt-image
                v-if="
                  !isDesktopVersion &&
                  !isSmall &&
                  imageExists(appConfig.VueSettingsPreRun.MainPageSEOBannerTablet)
                "
                :alt="appConfig.VueSettingsPreRun.MainPageSEOBannerDescription"
                :image="appConfig.VueSettingsPreRun.MainPageSEOBannerTablet"
              />
              <arora-nuxt-image
                v-else-if="isSmall && imageExists(appConfig.VueSettingsPreRun.MainPageSEOBannerMobile)"
                :alt="appConfig.VueSettingsPreRun.MainPageSEOBannerDescription"
                :image="appConfig.VueSettingsPreRun.MainPageSEOBannerMobile"
              />
              <arora-nuxt-image
                v-else-if="imageExists(appConfig.VueSettingsPreRun.MainPageSEOBannerDesktop)"
                :alt="appConfig.VueSettingsPreRun.MainPageSEOBannerDescription"
                :image="appConfig.VueSettingsPreRun.MainPageSEOBannerDesktop"
              />
            </div>
          </div>

          <div
            class="v-seo-text"
            :class="{
              'v-gradient-spoiler':
                appConfig.VueSettingsPreRun.ShowSEOTextType === 'hideWithSpoiler' &&
                !isExpanded &&
                showButton
            }"
            :style="showButton ? computedStyle : null"
            v-html="text"
          />
          <div class="v-text-center v-mb-xxl">
            <arora-button
              v-if="showButton"
              class-name="v-btn-show-text"
              :label="translate(isExpanded ? 'seoText.hideText' : 'seoText.showText')"
              @click="toggleExpand"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="v-custom-image v-text-center"
      :class="{ 'v-mb--4': appConfig.VueSettingsPreRun.FooterLayout === 'Southfarthing' }"
    >
      <arora-nuxt-image
        v-if="imageExists(appConfig.VueSettingsPreRun.MainPageCustomImage)"
        :alt="appConfig.VueSettingsPreRun.MainPageSEOBannerDescription"
        :image="appConfig.VueSettingsPreRun.MainPageCustomImage"
      />
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { useWindowSize } from '@arora/common'

defineProps<{
  text: string
  isMainPage?: boolean
}>()

const { translate } = useI18nSanitized()

const { imageExists } = useImageInfo()
const { sanitize } = useI18nSanitized()
const { isDesktopVersion, isSmall, windowSize } = useWindowSize()

const appConfig = useAppConfig()

const isExpanded = ref<boolean>(false)
const showButton = ref<boolean>(false)
const spoilerHeight = computed(() => {
  if (appConfig.VueSettingsPreRun.ShowSEOTextType === 'hideWithoutSpoiler') {
    return 0
  } else if (appConfig.VueSettingsPreRun.ShowSEOTextType === 'hideWithSpoiler') {
    return appConfig.VueSettingsPreRun.SpoilerHeight
  }
  return 0
})

const computedStyle = computed(() => ({
  maxHeight: isExpanded.value ? 'fit-content' : `${spoilerHeight.value}px`,
  overflow: isExpanded.value || !showButton.value ? 'visible' : 'hidden',
  transition: 'max-height 0.3s ease'
}))

const toggleExpand = (): void => {
  isExpanded.value = !isExpanded.value
}

onMounted(async () => {
  if (import.meta.client) showButtonFullText()
})

function showButtonFullText(): void {
  const SEOHeight = document.querySelector<HTMLUListElement>('.v-seo-text')
  if (SEOHeight) {
    if (SEOHeight.scrollHeight > 0) {
      if (SEOHeight.scrollHeight > appConfig.VueSettingsPreRun.SpoilerHeight) {
        showButton.value = true
      }
    } else {
      showButton.value = true
    }
  } else {
    setTimeout(() => showButtonFullText(), 200)
  }
}

watch(() => windowSize.value, showButtonFullText)
</script>

<style lang="scss">
@use '~/assets/mixins';
@use '~/assets/variables';

.v-seo-banner__block {
  position: relative;
  margin-bottom: 40px;
  .v-summary-text {
    .v-title {
      margin-bottom: 18px;
      font-size: 2rem;
      @include mixins.sm {
        font-size: 0.9rem;
      }
    }
    .v-description {
      font-size: 1.4rem;
      margin-bottom: 40px;
      @include mixins.sm {
        font-size: 0.8rem;
      }
    }
  }
  .v-text-absolute {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.v-btn-show-text {
  border-radius: variables.$BorderRadiusInput;
  border: 1px solid variables.$BorderColor;
  background: variables.$BodyElementsBackgroundOpaq90;
  color: variables.$BodyTextColor;
}
.v-seo-banner-link {
  width: fit-content;
  margin: 0 auto;
}
.v-seo-text {
  margin-bottom: 40px;
  &.v-gradient-spoiler {
    position: relative;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      content: ' ';
      height: 60px;
      background: linear-gradient(
        to bottom,
        variables.$BodyElementsBackgroundOpaq25 0%,
        variables.$BodyElementsBackground 100%
      );
    }
  }
}
</style>
